<template>
<div>

    <a-menu v-model='showMenu' offset-y max-width='300px'>
        <template v-slot:activator='{ on }'>
            <a-btn
                tile
                ellipsisv
                color='mono-grey-2'
                color-icon='mono-grey-40'
                class='ml-2'
                :_tooltip-text='$t("MENU")'
                v-on='on'
            />
        </template>
        
        <a-list dense >

            <template v-if="command && command.name">
                <a-list-item @click='dialog = true'>
                    <a-list-item-icon class='mr-2'>
                        <a-icon view color='mono-grey-50' />
                    </a-list-item-icon>
                    <a-list-item-content>
                        <a-list-item-title>
                        {{$t('VISUALIZATIONS')}}
                        </a-list-item-title>
                    </a-list-item-content>
                </a-list-item>


                <a-list-item v-if="currentView && currentView.name !== $t('DEFAULT')" @click='saveCurrentView'>
                    <a-list-item-icon class='mr-2'>
                        <a-icon save color='mono-grey-50' />
                    </a-list-item-icon>
                    <a-list-item-content>
                        <a-list-item-title>
                            {{$t('SAVE_ENTITY', {entity: `"${currentView.name}"` })}}
                        </a-list-item-title>
                    </a-list-item-content>
                </a-list-item>

                <a-list-item @click='saveViewAsDefault'>
                    <a-list-item-icon class='mr-2'>
                        <a-icon save color='mono-grey-50' />
                    </a-list-item-icon>
                    <a-list-item-content>
                        <a-list-item-title>
                            {{$t('SAVE_VIEW_AS_DEFAULT')}}
                        </a-list-item-title>
                    </a-list-item-content>
                </a-list-item>
            </template>


            <a-list-item v-if='!_disableOptions.includes("headers-selector")' @click='visibleHeadersDialog = true'>
                <a-list-item-icon class='mr-2'>
                    <a-icon small>view_column</a-icon>
                </a-list-item-icon>
                <a-list-item-content>
                    <a-list-item-title>
                        {{$t('EDIT_ENTITY', { entity: $t('COLUMNS').toLowerCase() })}}
                    </a-list-item-title>
                </a-list-item-content>
            </a-list-item>


           <a-list-item 
                v-if='!_disableOptions.includes("reports") && $listeners["click:toggle-table-reports"]'
                :disabled='!_items.length'
                @click='emitEvent()'
            >
                <a-list-item-action class='mr-2'>
                    <a-badge v-if="_items.length" :content="contPivots" class='icon-badge'>
                        <a-icon small>mdi-chart-pie</a-icon>
                    </a-badge>
                    <a-icon v-else small>mdi-chart-pie</a-icon>
                </a-list-item-action>
                <a-list-item-content>
                    <a-list-item-title>{{$t('REPORTS')}}</a-list-item-title>
                </a-list-item-content>
            </a-list-item>

            <a-list-item v-if="command && command.name && hasPermissionToGenerateWebservice" @click='generateWebservice'>
                <a-list-item-icon class='mr-2'>
                    <a-icon color='mono-grey-50' >mdi-api</a-icon>
                </a-list-item-icon>
                <a-list-item-content>
                    <a-list-item-title>
                    {{ $t("GENERATE_ENTITY", {entity: "Webservice"}) }}
                    </a-list-item-title>
                </a-list-item-content>
            </a-list-item>
           
           <a-list-item
                v-if='!_disableOptions.includes("advanced-filters")'
                @click='advancedFiltersDialog = true'
            >
                <a-list-item-icon class='mr-2'>
                    <a-icon small tablesearch :_tooltip-text='$t("ADVANCED_FILTERS")' />
                </a-list-item-icon>
                <a-list-item-content>
                        <a-list-item-title>
                            <a-badge class="my-0" v-if="advancedFiltersCount" inline :content='advancedFiltersCount'>
                                    {{$t('ADVANCED_FILTERS')}}
                            </a-badge>
                            <template v-else>
                                {{$t('ADVANCED_FILTERS')}}
                            </template>
                        </a-list-item-title>
                </a-list-item-content>
            </a-list-item>
            
            <a-list-item @click='openExportPage'>
                <a-list-item-icon class='mr-2'>
                    <a-icon export :_tooltip='false' />
                </a-list-item-icon>
                <a-list-item-content>
                    <a-list-item-title>
                        {{$t('EXPORT_TO')}}
                    </a-list-item-title>
                </a-list-item-content>
            </a-list-item>

            <slot name='append-list' />

        </a-list>

    </a-menu>

    <a-dialog v-model="dialog" max-width='500'>
        <a-card>
            <a-card-title>
                {{$t('VISUALIZATIONS')}}
                <a-spacer />
                <a-icon @click="dialog = false" color='mono-grey-40'>close</a-icon>
            </a-card-title>
            <a-card-subtitle>
                {{$t('TABLE_VIEWS_SET_OF_FILTERS_AND_COLUMNS')}}
            </a-card-subtitle>
                <a-list class="py-0">
                    <a-list-item class="justify-center" v-if="!views.length">
                        {{$t('NO_ITEMS')}}
                    </a-list-item>
                    <v-list-item-group
                        v-model="current"
                        color="primary"
                    >
                        <a-list-item 
                            v-for="(view, index) in views"
                            :key="index"
                            class="px-5"
                            :value='view.uuid'
                        >
                            
                            <a-list-item-content>
                                <a-list-item-title>
                                    {{view.name}}
                                </a-list-item-title>
                            </a-list-item-content>
                            
                            <a-list-item-icon>
                                <a-tooltip bottom>
                                    <template v-slot:activator='{ on }'>
                                        <a-btn v-on="on" icon small @click.stop="saveDefaultView(view)">
                                            <a-icon color='save' v-if="view.uuid === defaultViewUuid" small>star</a-icon>
                                            <a-icon v-else small>star_border</a-icon>
                                        </a-btn>
                                    </template>
                                    {{$t('USE_AS_DEFAULT')}}
                                </a-tooltip>

                                <a-btn icon small @click.stop="editView(index, view)">
                                    <a-icon small>edit</a-icon>
                                </a-btn>
                                <a-btn icon small @click.stop="deleteView(view)">
                                    <a-icon small>delete</a-icon>
                                </a-btn>
                            </a-list-item-icon>


                        </a-list-item>
                    </v-list-item-group>
                </a-list>
                <a-card-actions class="justify-end">
                     <a-btn 
                        color='mono-grey-40'
                        outlined
                        @click="dialog = false"
                    >
                        {{$t('CLOSE')}}
                    </a-btn>
                    <a-btn
                        color='save'
                        @click="viewsForm.dialog = true"
                    >
                    {{$t('ADD_NEW')}}
                    </a-btn>
                </a-card-actions>
        </a-card>
    </a-dialog>

    <a-dialog v-model="viewsForm.dialog" max-width='500'>
        <a-card>
            <a-card-title v-if="viewsForm.editedIndex !== null" >
                {{$t('EDIT_ENTITY', { entity: $t('VISUALIZATION').toLowerCase() })}}
            </a-card-title>
            <a-card-title v-else >
                {{$t('ADD_ENTITY', { entity: $t('VISUALIZATION').toLowerCase() })}}
            </a-card-title>
                <a-card-text>
                    <a-text-field
                        v-model="viewsForm.name"
                        hide-details
                        :label='$t("NAME")'
                    />
                </a-card-text>
                <a-card-actions class="justify-end">
                    <a-btn 
                        color='mono-grey-40'
                        outlined
                        @click="viewsForm.dialog = false"
                    >
                        {{$t('CANCEL')}}
                    </a-btn>
                    <a-btn
                        :disabled='!viewsForm.name'
                        color='save'
                        @click="saveView"
                    >
                    {{$t('SAVE')}}
                    </a-btn>
                </a-card-actions>
        </a-card>
    </a-dialog>

    <a-dialog v-model="visibleHeadersDialog" max-width='500'>
        <a-card>
            <a-card-title>
                {{$t('EDIT_ENTITY', { entity: $t('COLUMNS').toLowerCase() })}}
            </a-card-title>
            <a-card-text style="height:500px" class="overflow-y-auto">
                <draggable v-model="allHeaders" tag='a-row' no-gutters handle=".drag-handle">
                    <a-col
                        cols='12'
                        v-for="(header, index) in allHeaders"
                        :key="index"
                        style="border-bottom: 1px solid var(--v-mono-grey-10-base)"
                    >
                            <a-list-item>
                                <a-list-item-action>
                                    <div class="d-flex align-center">
                                        <a-icon
                                            class="drag-handle mr-4"
                                            style="cursor:grab"
                                        >
                                            drag_indicator
                                        </a-icon>
                                        <a-tooltip bottom>
                                            <template v-slot:activator='{on}'>
                                                <a-simple-checkbox v-on="on" v-model="header.visible" />
                                            </template>
                                                {{$t('SHOW_ENTITY', { entity: $t('COLUMN') })}}
                                        </a-tooltip>
                                    </div>
                                </a-list-item-action>
                                <a-list-item-content>
                                    <a-text-field
                                        v-model="header.label"
                                        :label='header.text || header.value'
                                        dense
                                        hide-details
                                    />
                                </a-list-item-content>
                            </a-list-item>
                    </a-col>
                </draggable>
            </a-card-text>
            <a-card-actions class="justify-end">
                <a-btn
                    @click="visibleHeadersDialog = false"
                    color='mono-grey-40'
                    outlined
                >
                    {{$t('CLOSE')}}
                </a-btn>
                <a-btn
                    @click="saveVisibleHeaders"
                    color='save'
                >
                    {{$t('APPLY')}}
                </a-btn>
            </a-card-actions>
        </a-card>
    </a-dialog>

       <a-dialog v-model='advancedFiltersDialog' max-width='1200' fullscreen>
            <a-card>
                <a-card-title>
                    {{$t('ADVANCED_FILTERS')}}
                    <a-spacer />
                    <a-btn
                        close
                        icon
                        :small-icon='false'
                        color-icon='mono-grey-40'
                        @click="advancedFiltersDialog = false"
                    />
                </a-card-title>
                
                <a-card-text>
                    <a-datatable-advanced-filters
                        :_filters.sync="advancedFilters"
                        :_items='_items'
                    />
                </a-card-text>

            </a-card>
        </a-dialog>

</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { USER_CONFIG_KEYS } from './services/consts'
import { GetUserConfigs, SaveUserConfig, DeleteUserConfig } from './services/request'
import { PREFERENCES_KEYS } from '../../../../../src/components/core/dataTableReport/services/consts';
import { SaveWebService } from '@/pages/webservices/services/request';
import HomeConts from '@/pages/home/services/consts.ts';

export default {
    components: {
        draggable: () => import("vuedraggable")
    },
    props: {
        /**
         * Command to use in export page
         */
        _command: {
            type: Object,
            default: null
        },
        /**
         * Options of export page
         */
        _exportOptions: {
            type: Object,
            default: () => ({})
        },
        /**
         * Headers to use export data
         */
        _headers: {
            type: Array,
            default: () => []
        },
        /**
         * Headers extended from the dynamic filters
         */
        _extendedHeaders: {
            type: Array,
            default: () => []
        },
        /**
         * Visible Headers to sync
         */
        _visibleHeaders: {
            type: Array,
            default: () => []
        },
        /**
         * Filters to sync
         */
        _advancedFilters: {
            type: Object,
            default: () => ({})
        },
        /**
         * items to use export data
         */
        _items: {
            type: Array,
            default: () => []
        },
        /**
         * Dense prop to sync
         */
        _dense: {
            type: Boolean,
            default: null
        },
        // disable some options
        _disableOptions: {
            type: Array,
            default: () => []
        },
        // use other key to save view
        _preferenceKey: {
            type: String,
            default: null
        },
        /**
         * Make a request when stating the app
         */
        _requestOnStart: {
            type: Boolean,
            default: false
        },
        /**
         * Disables default view loading on table mounting
         */
        _disableDefaultViewInitialLoading: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            showMenu: false,
            dialog: false,
            current: null,
            defaultViewUuid: null,
            views: [],
            viewsForm: {
                dialog: false,
                name: '',
                editedIndex: null
            },
            visibleHeadersDialog: false,
            datasetHeaders: [],
            allHeaders: [],
            contPivots: 0,
            advancedFiltersDialog: false,
            currentPage: null,
            operationPanelSessionName: null
        }
    },
    computed: {
        dense: {
            get(){
                return this._dense;
            },
            set(value) {
                this.$emit('update:_dense', value)
            }
        },
        command: {
            get: function () {
                return this._command
            },
            set: function(value) {
                this.$emit('update:_command', value)
            }
        },
        visibleHeaders: {
            get: function () {
                return this._visibleHeaders
            },
            set: function(value) {
                this.$emit('update:_visibleHeaders', value)
            }
        },
        extendedHeaders: {
            get: function () {
                return this._extendedHeaders
            },
            set: function () {
                this.$emit('update:_extendedHeaders', value)
            }
        },
        advancedFilters: {
            get: function () {
                return this._advancedFilters
            },
            set: function(value) {
                this.$emit('update:_advancedFilters', value)
            }
        },
        advancedFiltersCount: function () {
            return Object.values(this.advancedFilters).filter(v => v && v !== '').length
        },
        currentView: function () {
            return this.views.find(v => v.uuid === this.current)
        },
        preferenceName: function () {
            if (this._preferenceKey) {
                return this._preferenceKey;
            }

            if (this.command) {
                return this.command.name;
            }

            return null

        },
        isCustomViewBeingUsed: function () {
            return this.current !== null && this.current !== undefined
        },
        hasPermissionToGenerateWebservice: function() {
            let valid = false;

            let permissions = this.$_aura.getArray(this.$store.getters.getUser.permissions?.GRUPO_PERMISSOES);

            permissions.forEach(e => {
                if (e.NOME_GRUPO_PERMISSOES === 'Administradores' || e.NOME_GRUPO_PERMISSOES === 'Webservice - Manutenção'){
                    valid = true;
                } 
            });

            return valid;
        },
    },
    watch: {
        _headers: {
            handler: 'load',
            deep: true,
            immediate: true
        },
        _items: {
            handler: 'setHeaders',
            immediate: true
        },
        'currentView': {
            handler: 'setView',
            immediate: true
        },
        'viewsForm.dialog': function (value){
            if (!value) {
                this.viewsForm.name = '';
                this.viewsForm.editedIndex = null
            }
        },
        visibleHeadersDialog: function (value) {
            if (!value) {
                this.allHeaders.sort((a,b) => a.order - b.order)
            }
        },
        current: {
           deep: true,
           handler: 'emitDataTableViewChanged',
        },
    },
    methods: {
        load: async function () {
            if (this.command && this.command.name) {
                await this.setCommandViews()
                await this.setCommandDefaultView()
                this.setInitialView()
            }

            const pageName = this.$store.getters.getCurrentPage;
            let currentPage = this.$store.getters.getPages.find(page => page.name === pageName);
            this.operationPanelSessionName = currentPage.name;

            if (currentPage.componentName === HomeConts.HOME) {
                currentPage = this.$store.getters.getHomePage;
            }

            this.currentPage = currentPage || null;

            if (this.currentView && this.currentView.headers) {
                this.allHeaders = this.currentView.headers.slice()
            } else {
                this.allHeaders = [];
            }

            this.setHeaders()

            this.allHeaders.sort((a,b) => a.order - b.order)

            this.setVisibleHeaders()
            this.countPivots();

            this.$emit('ready')

        },
        setInitialView: function () {            
            if (this._disableDefaultViewInitialLoading) {
                return;
            }

            if (this.$route.query.viewId) {
                this.command.initializeLoading = true;
                this.current = this.$route.query.viewId
                this.$router.push({ query: {} })
                return
            }

            if (this.isCustomViewBeingUsed) {
                return
            }

            this.current =  this.defaultViewUuid
        },
        setCommandViews: async function (){
            const command = new GetUserConfigs()
                
            const request = await command.callCommand({
                name: `${USER_CONFIG_KEYS.DATA_TABLES_VIEWS}:${this.preferenceName}:*`
            });
            const items = this.$util.getArrayByNode(request, command.node)
                .map(i => JSON.parse(i.VALOR))

            this.views = items;
        },
        setCommandDefaultView: async function (){
            const command = new GetUserConfigs()
                
            const request = await command.callCommand({
                name: `${USER_CONFIG_KEYS.DATA_TABLE_DEFAULT_VIEW}:${this.preferenceName}`
            });

            const item = this.$util.getObjectByNode(request,  command.node)

            this.defaultViewUuid = item.VALOR || null
        },
        setView: function () {            
            if (!this.currentView && this.command.name && !this.command.v2) {
                this.command.values = this.command._initValue
            }

            if (!this.currentView) {
                this.allHeaders = this.allHeaders.map(h => ({
                    ...h,
                    visible: this._headers.some(_h => _h.value === h.value && _h.text === h.text),
                    label: null,
                }))
                this.setVisibleHeaders()
                return
            }

            if (this.command.v2) {
                this.command.setParams(this.currentView.filters)
            } else {
                this.command.values = {
                    ...this.command._initValue,
                    ...this.currentView.filters,
                }
            }

            this.advancedFilters = this.currentView.advancedFilters || {}

            const viewHeaders = this.currentView.headers || []

            if (viewHeaders.length) {
                this.allHeaders = this.allHeaders.map(h => {
                    const header = viewHeaders.find(vh => vh.text === h.text && vh.value === h.value)

                    if (!header) {
                        return h
                    }

                    return header
                })
            } else {
                this.allHeaders = this.allHeaders.map(h => ({
                    visible: this._headers.some(_h => _h.value === h.value && _h.text === h.text),
                    ...h,
                }))
            }

            this.allHeaders.sort((a,b) => a.order - b.order)

            this.setVisibleHeaders();


        },
        setVisibleHeaders(){
            this.visibleHeaders = this.allHeaders
                .slice()
                .filter(h => h.visible)
                .map(h => {
                    const originHeader = this._headers.find(_h => _h.value === h.value)

                    return {
                        ...originHeader,
                        ...h,
                        text: h.label || h.text
                    }
                })
        },
        countPivots: async function () {
                const command = new GetUserConfigs();

                const request = await command.callCommand({
                    name: `${PREFERENCES_KEYS.DATA_TABLE_PIVOT_REPORT}:${this._command._name}:*`,
                });
                this.contPivots = request.RAIZ.USUARIO_CONFIGURACOES.USUARIO_CONFIGURACOES.length;
        },
        setHeaders: function () {
            if (this._items.length) {
                this.datasetHeaders = Object.keys(this._items[0])
                    .map(key => ({
                        text: key,
                        value: key,
                    }))
            }

            const headers = this._headers.map(h => ({
                visible: true,
                ...h,
            }))

            const datasetNotAdded = this.datasetHeaders
                .filter(h => !headers.some(ah => ah.text === h.text && ah.value === h.value))
                .filter(h => !this.allHeaders.some(ah => ah.text === h.text && ah.value === h.value))

            const headerNotAdded = headers
                .filter(h => !this.allHeaders.some(ah => ah.text === h.text && ah.value === h.value))
            
            this.allHeaders = this.allHeaders
                .concat(headerNotAdded)
                .concat(datasetNotAdded)
                .map((h, index) => ({
                    ...h,
                    order: 99 + index,
                }))

            headers.forEach(h => {
                const header = this.allHeaders.find(ah => h.value === ah.value && h.text === ah.text);

                if (!this.isCustomViewBeingUsed && header && header.visible !== h.visible) {
                    header.visible = h.visible;
                }

                if (header && header.order !== h.order) {
                    header.order = h.order;
                }
            })
        },
        getTableCurrentViewState: function (){
            const excludePages = [
                'Home'
            ];

            const state = {
                commandName: this.command.name,
                advancedFilters: this.advancedFilters,
                headers: this.allHeaders.slice()
                    .map((h, index) => ({
                        text: h.text,
                        label: h.label,
                        value: h.value,
                        order: index,
                        visible: h.visible
                    })),
            }
            
            if (this.currentPage) {
                state.page = this.currentPage.componentName
                state.pageTitle = this.currentPage.title
                state.pageProps = this.currentPage.props
            }

            if (this.currentPage && this.currentPage.componentName === HomeConts.OPERATION_PANEL) {
                const sessionPanel = this.$store.getters['operationPanel/getSessionPanel'](this.operationPanelSessionName);
                const indexCurrentPanel = sessionPanel.currentMenuId - 1;
                const currentPanel = sessionPanel.menu[indexCurrentPanel];

                state.page = currentPanel.item.component.resolved.extendOptions.name;
                state.pageTitle = currentPanel.item.title;
                state.pageProps = currentPanel.item.props;
            }

            if (this.command.v2) {
                state.filters = this.command.getParams();
            } else {
                state.filters = Object.entries(this.command.fields)
                    .reduce((all, [name, field]) => ({
                            ...all,
                            [name]: field.value
                        })
                    , {});
            }

            return state
        },
        saveVisibleHeaders(){
            this.allHeaders = this.allHeaders.map((h,index) => ({
                ...h,
                order: index,
            }));

            this.setVisibleHeaders();

            this.visibleHeadersDialog = false
        },
        saveView: async function () {
            const view = {
                uuid: uuidv4(),
                name: this.viewsForm.name,
                commandName: this.command.name,
                ...this.getTableCurrentViewState()
            }

            if (this.viewsForm.editedIndex !== null) {
                view.uuid = this.views[this.viewsForm.editedIndex].uuid
                view.filters = this.views[this.viewsForm.editedIndex].filters
                view.advancedFilters = this.views[this.viewsForm.editedIndex].advancedFilters
                view.headers = this.views[this.viewsForm.editedIndex].headers
            }
            
            await new SaveUserConfig().callCommand({
                name: `${USER_CONFIG_KEYS.DATA_TABLES_VIEWS}:${this.preferenceName}:${view.uuid}`,
                value: view
            });

            await this.setCommandViews();

            this.viewsForm.dialog = false

            this.setView()
        },
        saveDefaultView: async function (view) {
            await new SaveUserConfig().callCommand({
                name: `${USER_CONFIG_KEYS.DATA_TABLE_DEFAULT_VIEW}:${this.preferenceName}`,
                value: view.uuid === this.defaultViewUuid ? null : view.uuid
            })

            await this.setCommandDefaultView()
            this.emitDataTableViewChanged();
        },
        saveViewAsDefault: async function () {
            const view = {
                name: this.$t('DEFAULT'),
                uuid: uuidv4(),
                ...this.getTableCurrentViewState()
            }
    
            const defaultView = this.views.find(v => v.name === view.name)

            if (defaultView) {
                view.uuid = defaultView.uuid
            }

            await new SaveUserConfig().callCommand({
                name: `${USER_CONFIG_KEYS.DATA_TABLES_VIEWS}:${this.preferenceName}:${view.uuid}`,
                value: view
            });

            if (this.defaultViewUuid !== view.uuid) {
                await this.saveDefaultView(view)
                await this.setCommandDefaultView();
            }

            await this.setCommandViews();
            this.emitDataTableViewChanged();
        },
        saveCurrentView: async function () {
            const view = {
                ...this.currentView,
                ...this.getTableCurrentViewState(),
            }

            await new SaveUserConfig().callCommand({
                name: `${USER_CONFIG_KEYS.DATA_TABLES_VIEWS}:${this.preferenceName}:${view.uuid}`,
                value: view
            });

            await this.setCommandViews();
            this.emitDataTableViewChanged();
        },
        emitDataTableViewChanged: function () {
            const view = this.$lodash.cloneDeep(this.currentView);
            this.$emit('data-table-view-changed', view);
        },
        deleteView: async function (view) {

            await new DeleteUserConfig().callCommand({
                name: `${USER_CONFIG_KEYS.DATA_TABLES_VIEWS}:${this.preferenceName}:${view.uuid}`,
            })

            await this.setCommandViews()
        },
        editView: async function (index, view) {
            this.viewsForm.editedIndex = index;
            this.viewsForm.name = view.name;
            this.viewsForm.dialog = true;
        },
        getHeaders: function () {
            if (this.visibleHeaders && this.visibleHeaders.length) {
                return this.visibleHeaders;
            }

            if (this._headers && this._headers.length) {
                return this._headers;
            }

            const firsItem = this.$lodash.get(this._items, '[0]', {})

            return Object.keys(firsItem).map(key => ({
                text: key,
                value: key,
            }))
        },
        openExportPage: function () {
            const data = this._items.slice();
            const headers = this.getHeaders();
            const columns = headers.filter(h => h.hideInReport !== false);
            const filename = this.$moment().format('L').replaceAll('/', '-');

            const {
                useCommand,
                latitudeField,
                longitudeField,
                exportPath,
            } = this._exportOptions;

            if (!useCommand) {
                this.$util.openPage('ExportPopup', {
                    isDialog: true,
                    dialog: { fullscreen: false, maxWidth: 500 },
                    props: {
                        _dataExport: data,
                        _columnSort: columns,
                        _filename: filename,
                        _latitudeField: latitudeField,
                        _longitudeField: longitudeField,
                    }
                })

                return;
            }

            if (!this._command) {
                throw new Error('a-data-table: useCommand must have a _command prop')
            }

            const commandFilters = this.$util
                    .getFiltersByCommand(this._command, true, ['PAGE', 'PAGE_SIZE'])
                    .filter(f => f.value && f.value !== "null")
                    .reduce((filters, f) => {
                        return {
                            ...filters,
                            [f.key]: f.value
                        }
                    }, {});

            // Still need a better solution
            Object.keys(commandFilters).forEach((key) => {
                if (Array.isArray(commandFilters[key])) {
                    commandFilters[key] = commandFilters[key].join(",")
                }
            });

            this.$util.openPage('ExportPopup', {
                isDialog: true,
                dialog: { fullscreen: false, maxWidth: 500 },
                props: {
                    _commandName: this._command.name,
                    _parameters: commandFilters,
                    _filename: filename,
                    _latitudeField: latitudeField,
                    _longitudeField: longitudeField,
                    _exportPath: exportPath,
                }
            });
        },
        generateWebservice: async function () {
            const path = `/webservice-${this.command.name.toLowerCase()}`;
            const description = `${this.$t('WEB_SERVICE_NEW')}, ${this.command.name.toLowerCase()}`;
            const commandWeb = this.command.name;
            this.$util.openPage({
                name: 'WebServiceGeneral', title: this.$t('WEB_SERVICE_NEW'),
            }, {
                isDialog: true,
                props: {
                    _path: path,
                    _description: description,
                    _commandWeb: commandWeb,
                },
                events: [
                    {
                        name: 'open-new-webservice', callback: this.openNewWebservice,
                    },
                ],
            });
        },
        openNewWebservice: async function(params) {
            const { idWebservice, description } = params;
            const command = this.command;
            
            this.$util.openPage({
                name: 'WebServiceGeneral', title: `${description} - (${idWebservice})`,
            }, {
                isDialog: false,
                props: {
                    _webserviceId: idWebservice,
                    _command: command,
                },
            });
        },
        emitEvent: function () {
            this.$emit('click:toggle-table-reports', this.allHeaders);
        }
    }
}
</script>
<style>
    .v-badge__badge {
        font-size: 10px !important;
        height: 16px !important;
        min-width: 16px !important;
    }
</style>