import Vue from 'vue';
import i18n from '@/i18n';
const state = Vue.observable({
    isTgAdmin: null,
    mapOptions: {
        isMapAvailable: false,
        sessionId: null,
        componentId: null,
    },
    boardModels: null,
    boardTypes: null,
    fixtures: null,
    defects: null,
    deviceTypes: null,
    deviceGroups: null,
    deviceConfigs: null,
    companies: null,
    commandTypes: null,
    lampModes: null,
    schedules: null,
    scheduleStatus: null,
    scheduleAction: {
        modes: null,
        modeTypes: null,
        operations: null,
    },
    alertTypes: null,
    hardwareAlertTypes: null,
    measurementConfigs: null,
    measurementTypes: null,
    deviceStates: null,
    companyDevice: {
        selectedIds: [],
        selectedDeviceIds: [],
    },
    tgRequestingPosition: false,
    canceledAllocation: false,
    allocationLatitude: 0,
    allocationLongitude: 0,
    rules: {
        required: (value) => {
            if (value !== 0 && !value) {
                return i18n.t('REQUIRED_FIELD');
            }
            return true;
        },
        // check if array have at least one item
        selectOne: (value) => {
            if (value) {
                return value.length > 0 || i18n.t('SELECT_AT_LEAST_ONE_ITEM');
            }
            return true;
        },
        numeric: (value) => {
            if (value) {
                // eslint-disable-next-line no-restricted-globals
                return !isNaN(value) || i18n.t('MUST_BE_A_NUMBER');
            }
            return true;
        },
    },
    numberOfUuidsAvailableToPrint: 0,
    entitiesThatStartedTests: '{}',
});
export default state;
