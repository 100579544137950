/* eslint-disable object-curly-newline */
import { Entity } from '@inputs/entity.ts';

const defaultProvider = {
    personalAllocationReason: new Entity({ fieldNameId: 'ID_ALOCACAO_PESSOAL_MOTIVO_MOVIMENTACAO', descriptionField: 'DESC_MOTIVO_MOVIMENTACAO', commandName: 'ConsultarMotivosMovimentacaoAlocacaoPessoal', providerField: 'MOTIVOS_MOVIMENTACAO.MOTIVO_MOVIMENTACAO' }),
    team: new Entity({ fieldNameId: 'ID_EQUIPE', descriptionField: 'DESC_EQUIPE', commandName: 'ConsultarEquipesVersionadas', providerField: 'EQUIPES.EQUIPE', searchField: 'equipes', params: { TODAS: 0 } }),
    attribute: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributos', providerField: 'ATRIBUTOS.ATRIBUTO', searchField: 'atributos' }),
    attributeByAssetType: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributosPorTipoPontoServico', providerField: 'ATRIBUTOS_TIPO_PONTO_SERVICO.ATRIBUTO_TIPO_PONTO_SERVICO' }),
    attributeValue: new Entity({ fieldNameId: 'ID_ATRIBUTO_VALOR', descriptionField: 'DESC_ATRIBUTO_VALOR', commandName: 'ConsultarAtributoValores', providerField: 'ATRIBUTO_VALORES.ATRIBUTO_VALOR', searchField: 'atributosValor' }),
    functionPeople: new Entity({ fieldNameId: 'ID_FUNCAO_COLAB', descriptionField: 'DESC_FUNCAO_COLAB', commandName: 'ConsultarFuncoesColab', providerField: 'FUNCOES_COLAB.FUNCAO_COLAB', searchField: 'DESC_FUNCAO_COLAB' }),
    state: new Entity({ fieldNameId: 'SIGLA_UF', descriptionField: 'SIGLA_UF', commandName: 'ConsultarEstados', providerField: 'ESTADOS.ESTADO', params: { FILTRO_PAIS_INSTANCIA: false } }),
    contractRegimeContributor: new Entity({ fieldNameId: 'ID_REGIME_CONTRATO_TRAB', descriptionField: 'DESC_REGIME_CONTRATO_TRAB', commandName: 'ConsultarRegimeContratoColab', providerField: 'REGIMES_CONTRATO_COLAB.REGIME_CONTRATO_COLAB', searchField: 'DESC_REGIME_CONTRATO_TRAB' }),
    modelShutdownContributor: new Entity({ fieldNameId: 'ID_MODAL_DESLIGAMENTO_COLAB', descriptionField: 'DESC_MODAL_DESLIGAMENTO_COLAB', commandName: 'ConsultarModalDesligColab', providerField: 'MODAL_DESLIG_COLABS.MODAL_DESLIG_COLAB', searchField: 'DESC_MODAL_DESLIGAMENTO_COLAB' }),
    workLocation: new Entity({ fieldNameId: 'ID_LOCAL_EMPRESA', descriptionField: 'DESC_LOCAL_EMPRESA', commandName: 'ConsultarLocaisEmpresa', providerField: 'LOCAIS_EMPRESA.LOCAL_EMPRESA', searchField: 'DESC_LOCAL_EMPRESA' }),
    workDepartment: new Entity({ fieldNameId: 'ID_DEPTO_EMPRESA', descriptionField: 'DESC_DEPTO_EMPRESA', commandName: 'ConsultarDeptosEmpresa', providerField: 'DEPTOS_EMPRESA.DEPTO_EMPRESA', searchField: 'DESC_DEPTO_EMPRESA' }),
    certificateType: new Entity({ fieldNameId: 'ID_TIPO_CERTIFICADO', descriptionField: 'DESC_TIPO_CERTIFICADO', commandName: 'ConsultarTiposCertificadoColaborador', providerField: 'TIPOS_CERTIFICADO.TIPO_CERTIFICADO', searchField: 'DESC_TIPO_CERTIFICADO', editDialog: { page: { name: 'CoursesAndCertificatesTypesRegister' }, pageConfig: { isDialog: true }, params: { ID_TIPO_CERTIFICADO: '_idCertificate' } } }),
    certificateTypePerson: new Entity({ fieldNameId: 'ID_TIPO_CERTIFICADO', descriptionField: 'DESC_TIPO_CERTIFICADO', commandName: 'ConsultarTiposCertificadoColaborador', providerField: 'TIPOS_CERTIFICADO.TIPO_CERTIFICADO', searchField: 'DESC_TIPO_CERTIFICADO', params: { FISICA_JURIDICA: 'F' } }),
    worksiteType: new Entity({ fieldNameId: 'ID_TIPO_PARQUE_SERVICO', descriptionField: 'DESC_TIPO_PARQUE_SERVICO', commandName: 'ConsultarTipoParqueServico', providerField: 'TIPOS_PARQUE_SERVICO.TIPO_PARQUE_SERVICO', searchField: 'palavra' }),
    issueOriginType: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_OCORRENCIA', descriptionField: 'DESC_TIPO_ORIGEM_OCORRENCIA', commandName: 'ConsultarTipoOrigemOcorrencia', providerField: 'TIPOS_ORIGEM_OCORRENCIA.TIPO_ORIGEM_OCORRENCIA', params: { SEM_RECLAMANTE: 0 } }),
    issueType: new Entity({ fieldNameId: 'ID_TIPO_OCORRENCIA', descriptionField: 'DESC_TIPO_OCORRENCIA', commandName: 'ConsultarTipoOcorrencia', providerField: 'TIPOS_OCORRENCIA.TIPO_OCORRENCIA', params: { HABILITADO_PARQUE: 1, SOMENTE_ATRASADAS: 0, SOMENTE_PREVENTIVAS: 0, SOMENTE_NAO_PREVENTIVAS: 0 } }),
    issueAssetPriority: new Entity({ fieldNameId: 'SIGLA_PRIORIDADE_PONTO_OCORR', descriptionField: 'DESC_PRIORIDADE_PONTO_OCORR', commandName: 'ConsultarPrioridadesPontoOcorrencia', providerField: 'PRIORIDADES_PONTO_OCORRENCIA.PRIORIDADE_PONTO_OCORRENCIA', searchField: 'palavra' }),
    stockCenter: new Entity({ fieldNameId: 'ID_CENTRO_ESTOQUE', descriptionField: 'DESC_CENTRO_ESTOQUE', commandName: 'ConsultarCentrosEstoque', providerField: 'CENTROS_ESTOQUE.CENTRO_ESTOQUE', searchField: 'palavra', params: { ALOCACAO_COLABORADOR: 0, MOSTRAR_COLAB: 0, MOSTRAR_EQUIPE: 0, TANQUE_COMBUSTIVEL: 0 } }),
    stockCenterEmployee: new Entity({ fieldNameId: 'ID_CENTRO_ESTOQUE', descriptionField: 'NOME_COLABORADOR', commandName: 'ConsultarCentrosEstoque', providerField: 'CENTROS_ESTOQUE.CENTRO_ESTOQUE', searchField: 'palavra', params: { ALOCACAO_COLABORADOR: 1, MOSTRAR_COLAB: 1 } }),
    lightSpots: new Entity({ fieldNameId: 'ID_MODAL_PONTO_LUMINOSO', descriptionField: 'DESC_MODAL_PONTO_LUMINOSO', commandName: 'ConsultarModalPontoLuminoso', providerField: 'MODALS_PONTO_LUMINOSO.MODAL_PONTO_LUMINOSO', searchField: 'palavra' }),
    listings: new Entity({ fieldNameId: 'ID_LISTAGEM', descriptionField: 'DESC_LISTAGEM', commandName: 'ConsultarTelaDadosListagens', providerField: 'LISTAGENS.LISTAGEM', searchField: 'palavra' }),
    stockType: new Entity({ fieldNameId: 'SIGLA_TIPO_ORIG_DEST_ESTOQUE', descriptionField: 'DESC_TIPO_ORIG_DEST_ESTOQUE', commandName: 'ConsultarTiposOrigDestEstoque', providerField: 'TIPOS_ORIG_DEST_ESTOQUE.TIPO_ORIG_DEST_ESTOQUE', searchField: 'palavra' }),
    companyLocation: new Entity({ fieldNameId: 'ID_LOCAL_EMPRESA', descriptionField: 'DESC_LOCAL_EMPRESA', commandName: 'ConsultarLocaisEmpresa', providerField: 'LOCAIS_EMPRESA.LOCAL_EMPRESA', searchField: 'palavra' }),
    companyLocationType: new Entity({ fieldNameId: 'ID_TIPO_LOCAL_EMPRESA', descriptionField: 'DESC_TIPO_LOCAL_EMPRESA', commandName: 'ConsultarTiposLocalEmpresa', providerField: 'TIPOS.TIPO', searchField: 'palavra' }),
    uf: new Entity({ fieldNameId: 'SIGLA_UF', descriptionField: 'SIGLA_UF', commandName: 'ConsultarEstados', providerField: 'ESTADOS.ESTADO', params: { FILTRO_PAIS_INSTANCIA: false } }),
    regions: new Entity({ fieldNameId: 'ID_REGIAO', descriptionField: 'NOME_REGIAO', commandName: 'ConsultarRegioes', providerField: 'REGIOES.REGIAO', searchField: 'palavra' }),
    district: new Entity({ fieldNameId: 'ID_BAIRRO', descriptionField: 'NOME_BAIRRO', commandName: 'ConsultarBairroPorPalavra', providerField: 'BAIRROS.BAIRRO', searchField: 'PALAVRA' }),
    worksites: new Entity({ fieldNameId: 'ID_PARQUE_SERVICO', descriptionField: 'NOME_PARQUE_SERVICO', commandName: 'ConsultarParquesServico', providerField: 'PARQUES_SERVICO.PARQUE_SERVICO', searchField: 'PALAVRA', saveUserSelectionKey: 'worksiteId', params: { ATIVO: 1 } }),
    taxPlans: new Entity({ fieldNameId: 'ID_PLANO_TARIFA_ENERGIA', descriptionField: 'DESC_PLANO_TARIFA_ENERGIA', commandName: 'ConsultarPlanosTarifariosEnergia', providerField: 'PLANOS.PLANO', searchField: 'PALAVRA' }),
    defaultPassword: new Entity({ fieldNameId: 'ID_PADRAO', descriptionField: 'DESCRICAO', commandName: 'ConsultarPadraoSenha', providerField: 'PADROES_SENHA.PADRAO_SENHA', searchField: 'palavra' }),
    taxPlanType: new Entity({ fieldNameId: 'ID_BANDEIRA_PLANO_TARIFARIO', descriptionField: 'DESC_BANDEIRA_PLANO_TARIFARIO', commandName: 'ConsultarBandeiraPlanoTarifario', providerField: 'BANDEIRAS_PLANOS_TARIFARIOS.BANDEIRA_PLANO_TARIFARIO', searchField: 'PALAVRA' }),
    gadgets: new Entity({ fieldNameId: 'ID_APARELHO', descriptionField: 'NOME_APARELHO', commandName: 'ConsultarAparelho', providerField: 'APARELHOS.APARELHO' }),
    attributesScheme: new Entity({ fieldNameId: 'ID_ESQUEMA_ATRIBUTOS', descriptionField: 'DESC_ESQUEMA_ATRIBUTOS', commandName: 'ConsultarEsquemas', providerField: 'ESQUEMAS.ESQUEMA', params: { SGI: 0, LAUDO: 0 } }),
    teamType: new Entity({ fieldNameId: 'ID_TIPO_EQUIPE', descriptionField: 'DESC_TIPO_EQUIPE', commandName: 'ConsultarTipoEquipe', providerField: 'TIPO_EQUIPES.TIPO_EQUIPE' }),
    city: new Entity({ fieldNameId: 'ID_MUNICIPIO', descriptionField: 'NOME_MUNICIPIO', commandName: 'ConsultarMunicipiosParqueServico', providerField: 'MUNICIPIOS.MUNICIPIO' }),
    issueRegion: new Entity({ fieldNameId: 'ID_REGIAO', descriptionField: 'NOME_REGIAO', commandName: 'ConsultarRegioesOcorrencia', providerField: 'REGIOES.REGIAO' }),
    issueDistrict: new Entity({ fieldNameId: 'ID_BAIRRO', descriptionField: 'NOME_BAIRRO', commandName: 'ConsultarBairrosOcorrencia', providerField: 'BAIRROS.BAIRRO' }),
    groupingLocation: new Entity({ fieldNameId: 'ID_AGRUPAMENTO_LOCALIZACAO', descriptionField: 'DESC_AGRUPAMENTO_LOCALIZACAO', commandName: 'ConsultarAgrupamentoLocalizacao', providerField: 'AGRUPAMENTO_LOCALIZACOES.AGRUPAMENTO_LOCALIZACAO' }),
    typeOrderService: new Entity({ fieldNameId: 'ID_TIPO_ORDEM_SERVICO', descriptionField: 'DESC_TIPO_ORDEM_SERVICO', commandName: 'ConsultarTipoOrdemServico', providerField: 'ORDENS_SERVICO.ORDEM_SERVICO' }),
    teamSimplify: new Entity({ fieldNameId: 'ID_EQUIPE', descriptionField: 'DESC_EQUIPE', commandName: 'ConsultarEquipesSimplificada', providerField: 'EQUIPES.EQUIPE', searchField: 'BUSCA' }),
    serviceOrderPhotoTemplate: new Entity({ fieldNameId: 'ID_TEMPLATE_FOTO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTemplateFotoOrdemServicoDisponiveis', providerField: 'TEMPLATES_FOTO.TEMPLATE_FOTO' }),
    taskType: new Entity({ fieldNameId: 'ID_TIPO_TAREFA', descriptionField: 'DESC_TIPO_TAREFA', commandName: 'ConsultarTipoTarefas', providerField: 'TIPOS.TIPO' }),
    issueReferralReason: new Entity({ fieldNameId: 'ID_MOTIVO_ENCAMINHAMENTO', descriptionField: 'DESC_MOTIVO_ENCAMINHAMENTO', commandName: 'ConsultarMotivoEncaminhamentoOcorrencia', providerField: 'MOTIVOS_ENCAMINHAMENTOS.MOTIVO_ENCAMINHAMENTO' }),
    issueReferralDestiny: new Entity({ fieldNameId: 'ID_DESTINO_ENCAMINHAMENTO', descriptionField: 'DESC_DESTINO_ENCAMINHAMENTO', commandName: 'ConsultarDestinoEncaminhamentoOcorrencia', providerField: 'DESTINOS_ENCAMINHAMENTOS.DESTINO_ENCAMINHAMENTO' }),
    attributeSchemaCategory: new Entity({ fieldNameId: 'ID_CATEGORIA_ESQUEMA_ATRIBUTOS', descriptionField: 'DESC_CATEGORIA_ESQUEMA_ATRIBUTOS', commandName: 'ConsultarCategoriaEsquemaAtributos', providerField: 'CATEGORIAS_ESQUEMA_ATRIBUTOS.CATEGORIA_ESQUEMA_ATRIBUTOS' }),
    attributeType: new Entity({ fieldNameId: 'COD_TIPO_ATRIBUTO', descriptionField: 'DESC_TIPO_ATRIBUTO', commandName: 'ConsultarTipoAtributo', providerField: 'TIPOS_ATRIBUTOS.TIPO_ATRIBUTO' }),
    attributeReference: new Entity({ fieldNameId: 'ID_REFERENCIA_ATRIBUTO', descriptionField: 'DESC_REFERENCIA_ATRIBUTO', commandName: 'ConsultarReferenciasAtributo', providerField: 'REFERENCIAS_ATRIBUTO.REFERENCIA_ATRIBUTO' }),
    attributeImageReference: new Entity({ fieldNameId: 'ID_REFERENCIA_ATRIBUTO', descriptionField: 'DESC_REFERENCIA_ATRIBUTO', commandName: 'ConsultarReferenciasAtributo', providerField: 'REFERENCIAS_ATRIBUTO.REFERENCIA_ATRIBUTO' }),
    ticketAssetCategory: new Entity({ fieldNameId: 'ID_CATEGORIA_PONTO_SOLICITACAO', descriptionField: 'DESCRICAO', commandName: 'ConsultarCategoriasPontoSolicitacao', providerField: 'CATEGORIAS.CATEGORIA_PONTO_SOLIC' }),
    ticketCancelReason: new Entity({ fieldNameId: 'ID_MOTIVO_CANCELAMENTO', descriptionField: 'DESC_MOTIVO_CANCELAMENTO', commandName: 'ConsultarMotivoCancelamento', providerField: 'MOTIVOS_CANCELAMENTO.MOTIVO_CANCELAMENTO' }),
    ticketAlertType: new Entity({ fieldNameId: 'ID_TIPO_ALERTA_SOLICITACAO', descriptionField: 'DESC_TIPO_ALERTA_SOLICITACAO', commandName: 'ConsultarTipoAlertaSolicitacao', providerField: 'TIPOS_ALERTAS_SOLICITACAOS.TIPO_ALERTA_SOLICITACAO' }),
    area: new Entity({ fieldNameId: 'ID_AREA', descriptionField: 'DESCRICAO', commandName: 'ConsultarAreas', providerField: 'AREAS.AREA' }),
    stampType: new Entity({ fieldNameId: 'ID_TIPO_CARIMBO', descriptionField: 'DESC_TIPO_CARIMBO', commandName: 'ConsultarTiposCarimbo', providerField: 'TIPOS_CARIMBO.TIPO_CARIMBO' }),
    skillGroup: new Entity({ fieldNameId: 'ID_GRUPO_COMPETENCIA_TEC', descriptionField: 'DESC_GRUPO_COMPETENCIA_TEC', commandName: 'ConsultarGrupoCompetenciaTec', providerField: 'GRUPOS_COMP_TEC.GRUPO_COMP_TEC' }),
    workScheme: new Entity({ fieldNameId: 'ID_ESQUEMA_HORARIO', descriptionField: 'DESC_ESQUEMA_HORARIO', commandName: 'ConsultarEsquemasHorario', providerField: 'HORARIOS.HORARIO' }),
    units: new Entity({ fieldNameId: 'ID_UNIDADE', descriptionField: 'NOME_UNIDADE', commandName: 'ConsultarUnidadesSimples', providerField: 'UNIDADES.UNIDADE' }),
    itemType: new Entity({ fieldNameId: 'ID_TIPO_ITEM', descriptionField: 'DESC_TIPO_ITEM', commandName: 'ConsultarTelaDadosTiposItens', providerField: 'TIPOS_ITENS.TIPO_ITEM' }),
    freightType: new Entity({ fieldNameId: 'SIGLA_TIPO_MATERIAL_FRETE', descriptionField: 'DESC_TIPO_MATERIAL_FRETE', commandName: 'ConsultarTiposMaterialFrete', providerField: 'TIPOS_MATERIAL_FRETE.TIPO_MATERIAL_FRETE' }),
    manufacturers: new Entity({ fieldNameId: 'ID_FABRICANTE', descriptionField: 'NOME', commandName: 'ConsultarFabricantes', providerField: 'FABRICANTES.FABRICANTE' }),
    modalitiesMaterialIdentification: new Entity({ fieldNameId: 'ID_MODAL_IDENT_MATERIAL', descriptionField: 'DESC_MODAL_IDENT_MATERIAL', commandName: 'ConsultarModalidadesIdentMaterial', providerField: 'MODALIDADES_IDENT_MATERIAL.MODALIDADE_IDENT_MATERIAL' }),
    stockCenterWorksite: new Entity({ fieldNameId: 'ID_CENTRO_ESTOQUE', descriptionField: 'DESC_CENTRO_ESTOQUE', commandName: 'ConsultarCentroEstoqueParqueServico', providerField: 'CENTROS.CENTRO' }),
    stockCenterTeam: new Entity({ fieldNameId: 'ID_CENTRO_ESTOQUE', descriptionField: 'DESC_CENTRO_ESTOQUE', commandName: 'ConsultarEquipeCentroEstoque', providerField: 'CENTROS.CENTRO' }),
    assetGroupingTypes: new Entity({ fieldNameId: 'ID_TIPO_AGRUPAMENTO_PONTO_SERVICO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTipoAgrupamentoPontoServico', providerField: 'TIPOS_AGRUPAMENTO_PONTO_SERVICO.TIPO_AGRUPAMENTO_PONTO_SERVICO' }),
    assetTypes: new Entity({ fieldNameId: 'ID_TIPO_PONTO_SERVICO', descriptionField: 'DESC_TIPO_PONTO_SERVICO', commandName: 'ConsultarTiposPontosServicos', providerField: 'TIPOS_PONTOS_SERVICOS.TIPO_PONTO_SERVICO' }),
    assetStatus: new Entity({ fieldNameId: 'ID_STATUS_PONTO_SERVICO', descriptionField: 'DESC_STATUS_PONTO_SERVICO', commandName: 'ConsultarStatusPontosServico', providerField: 'STATUS_PS.STATUS' }),
    technicalEvaluationType: new Entity({ fieldNameId: 'ID_MODALIDADE_LAUDO', descriptionField: 'DESC_MODALIDADE_LAUDO', commandName: 'ConsultarModalidadesLaudo', providerField: 'MODALIDADES.MODALIDADE' }),
    registerTechnique: new Entity({ fieldNameId: 'ID_TECNICA_CADASTRAMENTO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTecnicasCadastramento', providerField: 'TECNICAS.TECNICA' }),
    identifiedMaterialStatus: new Entity({ fieldNameId: 'SIGLA_STATUS_MATERIAL_IDENT', descriptionField: 'DESC_STATUS_MATERIAL_IDENT', commandName: 'ConsultaStatusMateriaisIdentificados', providerField: 'STATUS_IDENTS.STATUS_IDENT' }),
    relatedServiceOperation: new Entity({ fieldNameId: 'OPERACAO', descriptionField: 'DESC_OPERACAO', commandName: 'ConsultarTiposOperacaoVinculacao', providerField: 'OPERACOES.OPERACAO' }),
    getOrigem: new Entity({ fieldNameId: 'ID_ORIGEM_ITEM', descriptionField: 'DESCRICAO_ORIGEM_ITEM', commandName: 'ConsultarOrigem', providerField: 'ORIGEMS.ORIGEM' }),
    getItemClassification: new Entity({ fieldNameId: 'ID_TP_ITEM_CLASSIFICACAO', descriptionField: 'DESCRICAO_TP_ITEM_CLASSIFICACAO', commandName: 'ConsultarTpItemClassificao', providerField: 'TIPOS_ITEM_CLASSIFICACAO.TP_ITEM_CLASSIFICACAO' }),
    unit: new Entity({ fieldNameId: 'ID_UNIDADE', descriptionField: 'SIGLA_UNIDADE', commandName: 'ConsultarTelaDadosUnidades', providerField: 'UNIDADES.UNIDADE' }),
    stockSpecialMovement: new Entity({ fieldNameId: 'DESC_MOTIVO_MOV_ESTOQUE', descriptionField: 'DESC_MOTIVO_MOV_ESTOQUE', commandName: 'ConsultarDescricoesMotivosMovEstoque', providerField: 'DESC_MOTIVOS_MOV_ESTOQUE.DESC_MOTIVO_MOV_ESTOQUE' }),
    stockMovementReasonDesc: new Entity({ fieldNameId: 'DESC_MOTIVO_MOV_ESTOQUE', descriptionField: 'DESC_MOTIVO_MOV_ESTOQUE', commandName: 'ConsultarDescMotivosMovEspecial', providerField: 'MOTIVOS_MOV_ESTOQUE.MOTIVO_MOV_ESTOQUE' }),
    stockOriginTypeByReason: new Entity({ fieldNameId: 'SIGLA_TIPO_ORIG_DEST_ESTOQUE', descriptionField: 'DESC_TIPO_ORIG_DEST_ESTOQUE', commandName: 'ConsultarTiposOrigEstoquePorMotivo', providerField: 'TIPOS_ORIG_DEST_ESTOQUE.TIPO_ORIG_DEST_ESTOQUE' }),
    stockDestinyTypeByReason: new Entity({ fieldNameId: 'SIGLA_TIPO_ORIG_DEST_ESTOQUE', descriptionField: 'DESC_TIPO_ORIG_DEST_ESTOQUE', commandName: 'ConsultarTiposDestEstoquePorMotivo', providerField: 'TIPOS_ORIG_DEST_ESTOQUE.TIPO_ORIG_DEST_ESTOQUE' }),
    inventoryReason: new Entity({ fieldNameId: 'ID_MOTIVO_INVENTARIO', descriptionField: 'MOTIVO', commandName: 'ConsultarMotivosInventario', providerField: 'MOTIVOS.MOTIVO' }),
    dispensationOS: new Entity({ fieldNameId: 'ID_ORDEM_SERVICO', descriptionField: 'DESC_ORDEM_SERVICO', commandName: 'ConsultarOSEntrega', providerField: 'OSS.OS' }),
    domain: new Entity({ fieldNameId: 'ID_DOMINIO', descriptionField: 'DESC_DOMINIO', commandName: 'ConsultarDominio', providerField: 'DOMINIOS.DOMINIO' }),
    issueTypeOrigin: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_OCORRENCIA', descriptionField: 'DESC_TIPO_ORIGEM_OCORRENCIA', commandName: 'ConsultarTipoOrigemOcorrencia', providerField: 'TIPOS_ORIGEM_OCORRENCIA.TIPO_ORIGEM_OCORRENCIA' }),
    satisfactionSurveyTypeOrigin: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_PESQUISA_SATISFACAO', descriptionField: 'DESC_TIPO_ORIGEM_PESQUISA_SATISFACAO', commandName: 'ConsultarTipoOrigemPesquisaSatisfacao', providerField: 'TIPOS_ORIGEM_PESQUISA_SATISFACAO.TIPO_ORIGEM_PESQUISA_SATISFACAO' }),
    requestServicesType: new Entity({ fieldNameId: 'ID_TIPO_SERVICO_SOLICITACAO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTipoServicoSolicitacaoMVRP', providerField: 'TIPOS_SERVICOS_SOLICITACAO.TIPO_SERVICO_SOLICITACAO', editDialog: { page: { name: 'RequestServiceType' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_TIPO_SERVICO_SOLICITACAO: '_requestServiceTypeId' } } }),
    routingPanelProfile: new Entity({ fieldNameId: 'ID_PERFIL_ROTEIRIZACAO', descriptionField: 'NOME', commandName: 'ConsultarPerfisRoteirizacao', providerField: 'PERFIS_ROTEIRIZACAO.PERFIL_ROTEIRIZACAO' }),
    testCategory: new Entity({ fieldNameId: 'ID_CATEGORY', descriptionField: 'DESCRIPTION', commandName: 'GetAutomaticTestCategories', providerField: 'CATEGORIES.CATEGORY' }),
    validationType: new Entity({ fieldNameId: 'VALIDATION_TYPE_CODE', descriptionField: 'NAME', commandName: 'GetValidationTypes', providerField: 'VALIDATIONS.VALIDATION' }),
    customReport: new Entity({ fieldNameId: 'ID_RELATORIO_CUSTOMIZADO', descriptionField: 'DESC_RELATORIO_CUSTOMIZADO', commandName: 'ConsultarRelatoriosCustomizado', providerField: 'RELATORIOS_CUSTOMIZADOS.RELATORIO_CUSTOMIZADO' }),
    projectMeasurementOrigin: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_MEDICAO', descriptionField: 'DESC_TIPO_ORIGEM_MEDICAO', commandName: 'ConsultarTiposOrigemMedicao', providerField: 'TIPOS_ORIGEM_MEDICAO.TIPO_ORIGEM_MEDICAO' }),
    financialAccountType: new Entity({ fieldNameId: 'SIGLA_TIPO_CONTA_FINANC', descriptionField: 'DESC_TIPO_CONTA_FINANC', commandName: 'ConsultarTiposContaFinanceira', providerField: 'TIPOS_CONTA_FINANC.TIPO_CONTA_FINANC' }),
    chargeModalityType: new Entity({ fieldNameId: 'SIGLA_MODAL_COBRANCA', descriptionField: 'DESC_MODAL_COBRANCA', commandName: 'ConsultarModalidadesCobranca', providerField: 'MODALIDADES_COBRANCA.MODALIDADE_COBRANCA' }),
    assetPatterns: new Entity({ fieldNameId: 'ID_PADRAO_PS', descriptionField: 'DESC_PADRAO_PS', commandName: 'ConsultarPadroesPontoServico', providerField: 'PADROES_PONTO_SERVICO.PADRAO_PONTO_SERVICO' }),
    flowType: new Entity({ fieldNameId: 'COD_TIPO_FLUXO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTiposFluxos', providerField: 'TIPOS_FLUXO.TIPO' }),
    eventModels: new Entity({ fieldNameId: 'ID_MODELO_EVENTO', descriptionField: 'DESC_MODELO_EVENTO', commandName: 'ConsultarModeloEvento', providerField: 'MODELOS_EVENTOS.MODELO_EVENTO', params: { ATIVO: 1 } }),
    fuelType: new Entity({ fieldNameId: 'SIGLA_TIPO_COMBUSTIVEL', descriptionField: 'SIGLA_TIPO_COMBUSTIVEL', commandName: 'ConsultarTipoCombustivel', providerField: 'COMBUSTIVEIS.COMBUSTIVEL' }),
    financialCenterType: new Entity({ fieldNameId: 'SIGLA_TIPO_CENTRO_FINANC', descriptionField: 'DESC_TIPO_CENTRO_FINANC', commandName: 'ConsultarTiposCentroFinanc', providerField: 'TIPOS_CENTRO_FINANC.TIPO_CENTRO_FINANC' }),
    kitsMaterial: new Entity({ fieldNameId: 'ID_MODULO', descriptionField: 'DESC_ITEM', commandName: 'ConsultarKitsEntregaMaterial', providerField: 'KITS_ENTREGA_MATERIAL.KIT_ENTREGA_MATERIAL', params: { SIMPLES: 1 } }),
    tariffPlanEnergy: new Entity({ fieldNameId: 'ID_PLANO_TARIFA_ENERGIA', descriptionField: 'DESC_PLANO_TARIFA_ENERGIA', commandName: 'ConsultarPlanosTarifariosEnergia', providerField: 'PLANOS.PLANO' }),
    maintenanceStatus: new Entity({ fieldNameId: 'ID_STATUS_ATENDIMENTO_PS', descriptionField: 'DESC_STATUS_ATENDIMENTO_PS', commandName: 'ConsultarStatusAtendimentoPS', providerField: 'STATUS_ATENDIMENTO_PS.STATUS', params: { SOMENTE_ATIVOS: 0 } }),
    maintenanceReasons: new Entity({ fieldNameId: 'ID_MOTIVO_ATENDIMENTO_PS', descriptionField: 'DESC_MOTIVO_ATENDIMENTO_PS', commandName: 'ConsultarMotivosAtendimentoPS', providerField: 'MOTIVOS_ATENDIMENTO.MOTIVO_ATENDIMENTO' }),
    maintenanceSolutions: new Entity({ fieldNameId: 'ID_SOLUCAO_ATENDIMENTO_PS', descriptionField: 'DESC_SOLUCAO_ATENDIMENTO_PS', commandName: 'ConsultarSolucoesAtendimentoPS', providerField: 'SOLUCOES_ATENDIMENTO.SOLUCAO_ATENDIMENTO' }),
    photoType: new Entity({ fieldNameId: 'ID_TIPO_FOTO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTipoFoto', providerField: 'TIPOS_FOTO.TIPO_FOTO' }),
    roleCategories: new Entity({ fieldNameId: 'ID_CATEGORIA_FUNCAO_COLAB', descriptionField: 'DESC_CATEGORIA_FUNCAO_COLAB', commandName: 'ConsultarCategoriasFuncaoColab', providerField: 'CATEGORIAS.CATEGORIA' }),
    vehicleTypes: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'DESC_FAMILIA', commandName: 'ConsultarTiposVeiculos', providerField: 'TIPOS_VEICULO.TIPO_VEICULO' }),
    issueReferralStatus: new Entity({ fieldNameId: 'ID_STATUS_ENCAMINHAMENTO', descriptionField: 'DESC_STATUS_ENCAMINHAMENTO', commandName: 'ConsultarStatusEncaminhamento', providerField: 'STATUS_ENCAMINHAMENTOS.STATUS_ENCAMINHAMENTO' }),
    ticketCategory: new Entity({ fieldNameId: 'ID_CATEGORIA_PONTO_SOLICITACAO', descriptionField: 'DESCRICAO', commandName: 'ConsultarCategoriasPontoSolicitacao', providerField: 'CATEGORIAS.CATEGORIA_PONTO_SOLIC' }),
    sampleStatus: new Entity({ fieldNameId: 'ID_STATUS_AMOSTRA_PESQUISA', descriptionField: 'DESC_STATUS_AMOSTRA_PESQUISA', commandName: 'ConsultarStatusAmostra', providerField: 'STATUS.STATUS' }),
    publicPlace: new Entity({ fieldNameId: 'ID_LOGRADOURO', descriptionField: 'NOME_LOGRADOURO', commandName: 'ConsultarLogradouros', providerField: 'LOGRADOUROS.LOGRADOURO' }),
    cities: new Entity({ fieldNameId: 'ID_MUNICIPIO', descriptionField: 'NOME_MUNICIPIO', commandName: 'ConsultarMunicipioPorPalavra', providerField: 'MUNICIPIOS.MUNICIPIO' }),
    rejectionReasonType: new Entity({ fieldNameId: 'ID_TIPO_MOTIVO_REPROVACAO', descriptionField: 'DESC_TIPO_MOTIVO_REPROVACAO', commandName: 'ConsultarTipoMotivoReprovacao', providerField: 'TIPOS_MOTIVOS_REPROVACAOS.TIPO_MOTIVO_REPROVACAO' }),
    districts: new Entity({ fieldNameId: 'ID_BAIRRO', descriptionField: 'NOME_BAIRRO', commandName: 'ConsultarBairros', providerField: 'BAIRROS.BAIRRO' }),
    groupingAsset: new Entity({ fieldNameId: 'ID_AGRUPAMENTO_LOCALIZACAO', descriptionField: 'DESC_AGRUPAMENTO_LOCALIZACAO', commandName: 'ConsultarAgrupamentosPS', providerField: 'AGRUPAMENTOS.AGRUPAMENTO' }),
    reportTemplateFile: new Entity({ fieldNameId: 'ID_ARQUIVO_TEMPLATE', descriptionField: 'DESC_ARQUIVO_TEMPLATE', commandName: 'ConsultarArquivosTemplateRelatorio', providerField: 'ARQUIVOS.ARQUIVO' }),
    materialReservationType: new Entity({ fieldNameId: 'SIGLA_TIPO_SOLIC_MATERIAL', descriptionField: 'DESC_TIPO_SOLIC_MATERIAL', commandName: 'ConsultarTiposSolicMaterial', providerField: 'TIPOS_SOLIC_MATERIAL.TIPO_SOLIC_MATERIAL' }),
    subProject: new Entity({ fieldNameId: 'ID_SUB_OBRA', descriptionField: 'DESC_SUB_OBRA', commandName: 'ConsultarSubObras', providerField: 'SUB_OBRAS.SUB_OBRA' }),
    historicAssetVersion: new Entity({ fieldNameId: 'ID_VERSAO', descriptionField: 'DESC_VERSAO', commandName: 'ConsultarHistoricoVersaoPontoServico', providerField: 'VERSOES.VERSAO' }),
    reasonChangeStructureVersion: new Entity({ fieldNameId: 'COD_MOTIVO', descriptionField: 'DESC_MOTIVO', commandName: 'ConsultarMotivoTrocaVersaoEstrutura', providerField: 'MOTIVO_TROCA_VERSAO_ESTRUTURAS.MOTIVO_TROCA_VERSAO_ESTRUTURA' }),
    groupsAssetMedia: new Entity({ fieldNameId: 'ID_GRUPO_MEDIA_PONTO_SERVICO', descriptionField: 'NOME', commandName: 'ConsultarGruposMediaPS', providerField: 'GRUPOS_MEDIA_PONTO_SERVICO.GRUPO_MEDIA_PONTO_SERVICO' }),
    assetTypesByGeographic: new Entity({ fieldNameId: 'ID_TIPO_PONTO_SERVICO', descriptionField: 'DESC_TIPO_PONTO_SERVICO', commandName: 'TiposPontosServicos', providerField: 'TIPOS_PS.TIPO_PS' }),
    attributeSchemeByAsset: new Entity({ fieldNameId: 'ID_ESQUEMA_ATRIBUTOS', descriptionField: 'DESC_ESQUEMA_ATRIBUTOS', commandName: 'ConsultarEsquemaAtributosPorPS', providerField: 'ESQUEMAS_ATRIBUTOS.ESQUEMA_ATRIBUTOS' }),
    originTypeValidation: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_VALIDACAO', descriptionField: 'DESC_TIPO_ORIGEM_VALIDACAO', commandName: 'ConsultarTiposOrigemValidacao', providerField: 'TIPOS_ORIGEM.TIPO_ORIGEM' }),
    locationsReferences: new Entity({ fieldNameId: 'ID_REFERENCIA_LOCALIZACAO', descriptionField: 'DESC_REFERENCIA_LOCALIZACAO', commandName: 'ReferenciasLocalizacao', providerField: 'REFERENCIAS_LOCALIZACAO.REFERENCIA_LOCALIZACAO' }),
    activityRouteType: new Entity({ fieldNameId: 'ID_TIPO_LAUDO', descriptionField: 'DESC_TIPO_LAUDO', commandName: 'ConsultarTipoLaudo', providerField: 'TIPOS_LAUDO.TIPO_LAUDO' }),
    providers: new Entity({ fieldNameId: 'ID_LISTAGEM_FABRICANTES', descriptionField: 'DESC_LISTAGEM_FABRICANTES', commandName: 'ConsultarListagensFabricantes', providerField: 'LISTAGEM_FABRICANTES.LISTAGEM_FABRICANTE' }),
    activityRouteCategory: new Entity({ fieldNameId: 'COD_CATEGORIA_ATIVIDADE', descriptionField: 'DESC_CATEGORIA_ATIVIDADE', commandName: 'ConsultarCategoriaRotaAtividade', providerField: 'CATEGORIAS_ATIVIDADE.CATEGORIA_ATIVIDADE' }),
    projectExecutionType: new Entity({ fieldNameId: 'SIGLA_TIPO_EXECUCAO_OBRA', descriptionField: 'DESC_TIPO_EXECUCAO_OBRA', commandName: 'ConsultarTiposExecucaoObra', providerField: 'TIPOS_EXECUCAO_OBRA.TIPO_EXECUCAO_OBRA' }),
    projectLocationType: new Entity({ fieldNameId: 'ID_TIPO_LOCAL_OBRA', descriptionField: 'DESC_TIPO_LOCAL_OBRA', commandName: 'ConsultarTipoLocalObra', providerField: 'TIPO_LOCAL_OBRAS.TIPO_LOCAL_OBRA' }),
    schedulingDepedenceType: new Entity({ fieldNameId: 'ID_TIPO_DEPENDENCIA_AGEND', descriptionField: 'DESC_TIPO_DEPENDENCIA_AGEND', commandName: 'ConsultarTipoDependenciaAgend', providerField: 'TIPOS_DEPENDENCIAS_AGEND.TIPO_DEPENDENCIA_AGEND' }),
    schedule: new Entity({ fieldNameId: 'ID_AGENDAMENTO', descriptionField: 'DESC_AGENDAMENTO', commandName: 'ConsultarAgendamento', providerField: 'AGENDAMENTOS.AGENDAMENTO' }),
    dailyEventType: new Entity({ fieldNameId: 'COD_TIPO_EVENTO', descriptionField: 'DESC_TIPO_EVENTO', commandName: 'ConsultarTiposEventosDiarioTrabalho', providerField: 'TIPOS_EVENTOS_DIARIO_TRABALHO.TIPO_EVENTO_DIARIO_TRABALHO' }),
    warrantyLot: new Entity({ fieldNameId: 'ID_LOTE_GARANTIA_MATERIAL', descriptionField: 'DESC_LOTE_GARANTIA_MATERIAL', commandName: 'ConsultarLoteGarantiaItemNFE', providerField: 'LOTES_GARANTIA_MATERIAL.LOTE_GARANTIA_MATERIAL' }),
    documentTypes: new Entity({ fieldNameId: 'ID_TIPO_DOCUMENTO', descriptionField: 'DESC_TIPO_DOCUMENTO', commandName: 'ConsultarTiposDocumento', providerField: 'TIPOS_DOCUMENTO.TIPO_DOCUMENTO' }),
    workTimeAlocationType: new Entity({ fieldNameId: 'ID_TIPO_HORARIO_ALOC_PESSOAL', descriptionField: 'DESC_TIPO_HORARIO_ALOC_PESSOAL', commandName: 'ConsultarTiposHorarioAlocacao', providerField: 'TIPOS_HORARIO_ALOCACAO.TIPO_HORARIO_ALOCACAO' }),
    startAndEndAlocationType: new Entity({ fieldNameId: 'ID_TIPO_INIC_FIM_ALOC_PESSOAL', descriptionField: 'DESC_TIPO_INIC_FIM_ALOC_PESSOAL', commandName: 'ConsultarTiposInicioFimAlocacao', providerField: 'TIPOS_INIC_FIM_ALOCACAO.TIPO_INIC_FIM_ALOCACAO' }),
    teamsAllocated: new Entity({ fieldNameId: 'ID_EQUIPE', descriptionField: 'DESC_EQUIPE', commandName: 'ConsultarEquipesAlocPes', providerField: 'EQUIPES.EQUIPE' }),
    relevantFacts: new Entity({ fieldNameId: 'ID_FATO_RELEVANTE', descriptionField: 'RESUMO', commandName: 'ConsultarFatosRelevantes', providerField: 'FATOS_RELEVANTES.FATO_RELEVANTE' }),
    projectIssues: new Entity({ fieldNameId: 'ID_TIPO_OCORRENCIA_OBRA', descriptionField: 'DESC_TIPO_OCORRENCIA_OBRA', commandName: 'ConsultarTipoOcorrenciaObra', providerField: 'TIPOS_OCORRENCIAS_OBRA.TIPO_OCORRENCIA_OBRA' }),
    executionDefinitionMethod: new Entity({ fieldNameId: 'SIGLA_METODO_DEFINICAO_EXECUCAO', descriptionField: 'DESC_METODO_DEFINICAO_EXECUCAO', commandName: 'ConsultarMetodoDefinicaoExecucao', providerField: 'METODOS_DEFINICAO_EXECUCAO.METODO_DEFINICAO_EXECUCAO' }),
    movementAllocationType: new Entity({ fieldNameId: 'SIGLA_TIPO_MOV_ALOC_FINANC', descriptionField: 'DESC_TIPO_MOV_ALOC_FINANC', commandName: 'ConsultarDadosNovaMovAlocFinanceira', providerField: 'TIPOS_MOV_ALOCACAO_FINANC.TIPO_MOV_ALOCACAO_FINANC' }),
    projectReservationStatus: new Entity({ fieldNameId: 'SIGLA_STATUS_RESERVA_OBRA', descriptionField: 'DESC_STATUS_RESERVA_OBRA', commandName: 'ConsultarStatusReservaObra', providerField: 'STATUS_RESERVA_OBRA.STATUS' }),
    projectAplicationStatus: new Entity({ fieldNameId: 'SIGLA_STATUS_APLIC_OBRA', descriptionField: 'DESC_STATUS_APLIC_OBRA', commandName: 'ConsultarStatusAplicacaoObra', providerField: 'STATUS_APLIC_OBRA.STATUS' }),
    projectMeasurementStatus: new Entity({ fieldNameId: 'SIGLA_STATUS_MED_OBRA', descriptionField: 'DESC_STATUS_MED_OBRA', commandName: 'ConsultarStatusMedicaoObra', providerField: 'STATUS_MED_OBRA.STATUS' }),
    projectStatus: new Entity({ fieldNameId: 'ID_STATUS', descriptionField: 'DESC_STATUS', commandName: 'ConsultarStatusObra', providerField: 'STATUS_OBRA.STATUS' }),
    modernizationStatus: new Entity({ fieldNameId: 'ID_STATUS_MODERNIZACAO', descriptionField: 'DESC_STATUS_MODERNIZACAO', commandName: 'ConsultarStatusModernizacao', providerField: 'STATUS_MODERNIZACAO.STATUS_MODERNIZACAO' }),
    projectOperationType: new Entity({ fieldNameId: 'ID_TIPO_OPERACAO', descriptionField: 'DESC_TIPO_OPERACAO', commandName: 'ConsultarTipoOperacaoObra', providerField: 'TIPOS_OPERACAO_OBRA.TIPO_OPERACAO_OBRA' }),
    projectModality: new Entity({ fieldNameId: 'ID_MODAL_OBRA', descriptionField: 'DESC_MODAL_OBRA', commandName: 'ConsultarModalidadesObra', providerField: 'MODALIDADES_OBRA.MODALIDADE_OBRA' }),
    structureVersion: new Entity({ fieldNameId: 'ID_VERSAO', descriptionField: 'ID_DESC_VERSAO', commandName: 'ConsultarVersaoDimensionamentoObra', providerField: 'VERSOES_DIMENS_OBRA.VERSAO_DIMENS_OBRA' }),
    labor: new Entity({ fieldNameId: 'ID_MODAL_DIMENS_MO', descriptionField: 'DESC_MODAL_DIMENS_MO', commandName: 'ConsultarModalDimensMoObra', providerField: 'MODALIDADES_DIMENS_MO.MODALIDADE_DIMENS_MO' }),
    projectFreightMethod: new Entity({ fieldNameId: 'SIGLA_METODO_FRETE_OBRA', descriptionField: 'DESC_METODO_FRETE_OBRA', commandName: 'ConsultarMetodosFreteObra', providerField: 'METODOS_FRETE_OBRA.METODO_FRETE_OBRA' }),
    measurement: new Entity({ fieldNameId: 'ID_MEDICAO', descriptionField: 'NUM_MEDICAO', commandName: 'ConsultarMedicoes', providerField: 'MEDICOES.MEDICAO' }),
    measurementProjectType: new Entity({ fieldNameId: 'SIGLA_TIPO_OBRA_MEDICAO', descriptionField: 'DESC_TIPO_OBRA_MEDICAO', commandName: 'ConsultarTipoObraMedicao', providerField: 'TIPOS_MEDICOES.TIPO_MEDICAO' }),
    itemTypeSimple: new Entity({ fieldNameId: 'ID_TIPO_ITEM', descriptionField: 'DESC_TIPO_ITEM', commandName: 'ConsultaTipoItemSimples', providerField: 'TIPOS_ITENS.TIPO_ITEM', searchField: {} }),
    operarionsTypeLink: new Entity({ fieldNameId: 'OPERACAO', descriptionField: 'DESC_OPERACAO', commandName: 'ConsultarTiposOperacaoVinculacao', providerField: 'OPERACOES.OPERACAO', searchField: {} }),
    projectStrutures: new Entity({ fieldNameId: 'ID_ESTRUTURA', descriptionField: 'DESC_ESTRUTURA', commandName: 'ConsultarEstruturasObra', providerField: 'ESTRUTURAS.ESTRUTURA' }),
    materialIdentification: new Entity({ fieldNameId: 'COD_ITEM', descriptionField: 'DESC_ITEM', commandName: 'ConsultarMaterialIdentificado', providerField: 'MATERIAL_IDENTIFICADO' }),
    projectStudyStage: new Entity({ fieldNameId: 'ID_ETAPA_ESTUDO_PROJETO', descriptionField: 'DESC_ETAPA_ESTUDO_PROJETO', commandName: 'ConsultarEtapaEstudoProjeto', providerField: 'ETAPAS_ESTUDO_PROJETO.ETAPA_ESTUDO_PROJETO' }),
    stockCenterItemsToIdentify: new Entity({ fieldNameId: 'ID_CENTRO_ESTOQUE', descriptionField: 'DESC_ESTOQUE', commandName: 'ConsultarCentrosEstoqueItensAIdentificar', providerField: 'CENTROS_ESTOQUE_ITENS_A_IDENTIFICAR.CENTRO_ESTOQUE_ITENS_A_IDENTIFICAR' }),
    materialIdentificationExclusionReasons: new Entity({ fieldNameId: 'COD_MOTIVO_MOV_ESTOQUE', descriptionField: 'DESC_MOTIVO_MOV_ESTOQUE', commandName: 'ConsultarMotivosMovExclusaoMaterialIdent', providerField: 'MOTIVOS_MOV_ESTOQUE.MOTIVO_MOV_ESTOQUE' }),
    months: new Entity({ fieldNameId: 'ID_MES', descriptionField: 'DESC_MES', commandName: 'ConsultarMesDoAno', providerField: 'MESES_DO_ANO.MES' }),
    inconsistensyType: new Entity({ fieldNameId: 'ID_TIPO_INCONSIST_APONTAMENTO', descriptionField: 'DESC_TIPO_INCONSIST_APONTAMENTO', commandName: 'CarregarTipoInconsistencia', providerField: 'TIPOS_INCONSISTENCIA.TIPO_INCONSISTENCIA' }),
    taxType: new Entity({ fieldNameId: 'ID_TIPO_TRIBUTO', descriptionField: 'ID_DESC_TIPO_TRIBUTO', commandName: 'ConsultarTipoTributos', providerField: 'TIPOS_TRIBUTO.TIPO_TRIBUTO' }),
    taxSituationCode: new Entity({ fieldNameId: 'ID_CST', descriptionField: 'ID_DESC_CST', commandName: 'ConsultarCST', providerField: 'CSTS.CST' }),
    materialRequestStatus: new Entity({ fieldNameId: 'ID_STATUS_SOLIC_MATERIAL', descriptionField: 'DESC_STATUS_SOLIC_MATERIAL', commandName: 'ConsultarStatusSolicMaterial', providerField: 'STATUS_SOLIC_MATERIAL.STATUS' }),
    budgetStatus: new Entity({ fieldNameId: 'ID_STATUS_ORCAMENTO', descriptionField: 'DESC_STATUS_ORCAMENTO', commandName: 'ConsultarStatusOrcamentos', providerField: 'LISTA_STATUS_ORCAMENTO.STATUS_ORCAMENTO' }),
    budgetVersion: new Entity({ fieldNameId: 'ID_VERSAO', descriptionField: 'ID_DESC_VERSAO', commandName: 'ConsultarVersaoOrcamento', providerField: 'VERSOES_ORCAMENTO.VERSAO_ORCAMENTO' }),
    budgetComposition: new Entity({ fieldNameId: 'SIGLA_TIPO_COMPOS_ORCA', descriptionField: 'DESC_TIPO_COMPOS_ORCA', commandName: 'ConsultarModalOrcamento', providerField: 'TIPOS_COMPOSICAO.TIPO_COMPOSICAO' }),
    budgetEntity: new Entity({ fieldNameId: 'SIGLA_ENTIDADE_ORCAMENTO', descriptionField: 'DESC_ENTIDADE_ORCAMENTO', commandName: 'ConsultarModalOrcamento', providerField: 'ENTIDADES.ENTIDADE' }),
    budgetItems: new Entity({ fieldNameId: 'SIGLA_MODAL_ORCA_ITENS', descriptionField: 'DESC_MODAL_ORCA_ITENS', commandName: 'ConsultarModalidadesOrcamentos', providerField: 'ITENS.ITEM' }),
    budgetMaterials: new Entity({ fieldNameId: 'SIGLA_MODAL_ORCA_MATERIAIS', descriptionField: 'DESC_MODAL_ORCA_MATERIAIS', commandName: 'ConsultarModalidadesOrcamentos', providerField: 'MATERIAIS.MATERIAL' }),
    budgetLabor: new Entity({ fieldNameId: 'SIGLA_MODAL_ORCA_MO', descriptionField: 'DESC_MODAL_ORCA_MO', commandName: 'ConsultarModalidadesOrcamentos', providerField: 'MAOS_DE_OBRA.MAO_DE_OBRA' }),
    budgetThirdParty: new Entity({ fieldNameId: 'SIGLA_MODAL_ORCA_TERCEIROS', descriptionField: 'DESC_MODAL_ORCA_TERCEIROS', commandName: 'ConsultarModalidadesOrcamentos', providerField: 'TERCEIROS.TERCEIRO' }),
    biddingModality: new Entity({ fieldNameId: 'ID_MODAL_LICITACAO', descriptionField: 'DESC_MODAL_LICITACAO', commandName: 'ConsultarModalidadesLicitacao', providerField: 'MODALIDADES_LICITACAO.MODALIDADE_LICITACAO', params: { ID_VERSAO: 2 } }),
    biddingStatus: new Entity({ fieldNameId: 'ID_STATUS_LICITACAO', descriptionField: 'DESC_STATUS_LICITACAO', commandName: 'ConsultarStatusLicitacao', providerField: 'STATUS_LICITACAO.STATUS' }),
    billSaleServiceByFinancialEvent: new Entity({ fieldNameId: 'ID_NF_SERVICO', descriptionField: 'DESC_NOTA', commandName: 'ConsultarNFServicoPorEventoFinanc', providerField: 'NFS_SERVICO.NF_SERVICO' }),
    clientContracts: new Entity({ fieldNameId: 'ID_CONTRATO', descriptionField: 'DESC_ORCAMENTO', commandName: 'ConsultarContratos', providerField: 'CONTRATOS.CONTRATO' }),
    inputInvoiceFreight: new Entity({ fieldNameId: 'SIGLA_TIPO_FRETE', descriptionField: 'DESC_TIPO_FRETE', commandName: 'ConsultarTiposFreteNFEntrada', providerField: 'TIPOS_FRETE_NF_ENTRADA.TIPO_FRETE_NF_ENTRADA' }),
    financialScheduleStatus: new Entity({ fieldNameId: 'ID_STATUS_AGEND_FINANC', descriptionField: 'DESC_STATUS_AGEND_FINANC', commandName: 'ConsultarStatusAgendamentoFinanc', providerField: 'STATUS_AGENDAMENTOS.STATUS_AGENDAMENTO' }),
    transactionStatus: new Entity({ fieldNameId: 'COD_STATUS_TRANSACAO_FINANC', descriptionField: 'DESC_STATUS_TRANSACAO_FINANC', commandName: 'ConsultarStatusTransacaoFinanceira', providerField: 'STATUS_TRANSACOES.STATUS_TRANSACAO' }),
    quotationItemType: new Entity({ fieldNameId: 'ID_TIPO_ITEM', descriptionField: 'DESC_TIPO_ITEM', commandName: 'ConsultarTipoItemCotacao', providerField: 'TIPOS_ITENS.TIPO_ITEM' }),
    methodsCalculationFreightPurchase: new Entity({ fieldNameId: 'ID_METODO_CALCULO_FRETE', descriptionField: 'DESC_METODO_CALCULO_FRETE', commandName: 'ConsultarMetodosCalculoFreteCompra', providerField: 'METODOS_CALCULO_FRETE_COMPRA.METODO_CALCULO_FRETE_COMPRA' }),
    invoiceDocType: new Entity({ fieldNameId: 'COD_TIPO_DOC', descriptionField: 'DESCRICAO_TIPO_DOC', commandName: 'ConsultarTipoDocNota', providerField: 'TIPOS_DOC_NOTA.TIPO_DOC_NOTA' }),
    invoiceDocTypeSituation: new Entity({ fieldNameId: 'COD_SITUACAO_DOCUMENTO_NOTA', descriptionField: 'DESCRICAO_SITUACAO_DOCUMENTO_NOTA', commandName: 'ConsultarSituacaoDocumentoNota', providerField: 'SITUACAO_DOCUMENTO_NOTAS.SITUACAO_DOCUMENTO_NOTA' }),
    taxDocumentTemplate: new Entity({ fieldNameId: 'COD_MODELO_DOCUMENTO_FISCAL', descriptionField: 'DESCRICAO_MODELO_DOCUMENTO_FISCAL', commandName: 'ConsultarModeloDocumentoFiscal', providerField: 'MODELOS_DOCUMENTO_FISCAL.MODELO_DOCUMENTO_FISCAL' }),
    eletronicTransportKnowledgeType: new Entity({ fieldNameId: 'ID_TP_CONHECIMENTO_TRANSPORTE_ELETRONICO', descriptionField: 'DESCRICAO_TP_CONHECIMENTO_TRANSPORTE_ELETRONICO', commandName: 'ConsultarTpConhecimentoTransporteEletronico', providerField: 'TPS_CONHECIMENTO_TRANSPORTE_ELETRONICO.TP_CONHECIMENTO_TRANSPORTE_ELETRONICO' }),
    shippingIndustryNature: new Entity({ fieldNameId: 'ID_IND_NATUREZA_FRETE', descriptionField: 'DESCRICAO_IND_NATUREZA_FRETE', commandName: 'ConsultarIndNaturezaFrete', providerField: 'IND_NATUREZAS_FRETE.IND_NATUREZA_FRETE' }),
    cfopOperationNature: new Entity({ fieldNameId: 'ID_NATUREZA_OPERACAO', descriptionField: 'ID_DESC_NATUREZA_OPERACAO', commandName: 'ConsultarNaturezaOperacaoCFOP', providerField: 'NATUREZAS_OPERACAO_CFOP.NATUREZA_OPERACAO_CFOP' }),
    cfop: new Entity({ fieldNameId: 'ID_CFOP', descriptionField: 'ID_DESC_CFOP', commandName: 'ConsultarCFOP', providerField: 'CFOPS.CFOP' }),
    typesTaxTaxes: new Entity({ fieldNameId: 'ID_TIPO_TRIBUTO', descriptionField: 'DESCRICAO_TIPO_TRIBUTO', commandName: 'ConsultarTiposTributosFiscais', providerField: 'TIPOS_TRIBUTOS.TIPO_TRIBUTOS' }),
    financialEventTypes: new Entity({ fieldNameId: 'SIGLA_TIPO_EVENTO_FINANC', descriptionField: 'DESC_TIPO_EVENTO_FINANC', commandName: 'ConsultarTiposEventoFinanceiro', providerField: 'TIPOS_EVENTO_FINANC.TIPO_EVENTO_FINANC' }),
    projectDimensioningVersion: new Entity({ fieldNameId: 'ID_VERSAO', descriptionField: 'ID_DESC_VERSAO', commandName: 'ConsultarVersaoDimensionamentoObra', providerField: 'VERSOES_DIMENS_OBRA.VERSAO_DIMENS_OBRA' }),
    natureRevenueIndicator: new Entity({ fieldNameId: 'ID_INDICADOR_NATUREZA_RECEITA', descriptionField: 'COD_DESCRICAO', commandName: 'ConsultarIndicadorNaturezaReceita', providerField: 'INDICADORES_NATUREZA_RECEITA.INDICADOR_NATUREZA_RECEITA' }),
    natureRevenueRetentionIndicator: new Entity({ fieldNameId: 'ID_INDICADOR_NATUREZA_RETENCAO_FONTE', descriptionField: 'COD_DESCRICAO', commandName: 'ConsultarIndicadorNaturezaRetencaoFonte', providerField: 'INDICADORES_NATUREZA_RETENCAO_FONTE.INDICADOR_NATUREZA_RETENCAO_FONTE' }),
    workSimplify: new Entity({ fieldNameId: 'ID_OBRA', descriptionField: 'DESC_OBRA', commandName: 'ConsultarObrasSimplificada', providerField: 'OBRAS.OBRA' }),
    workDiaryErrorType: new Entity({ fieldNameId: 'ID_TIPO_ERRO', descriptionField: 'DESC_TIPO_ERRO', commandName: 'ConsultarTiposErroSincronizacao', providerField: 'TIPOS_ERRO.TIPO_ERRO' }),
    contractPolitics: new Entity({ fieldNameId: 'ID_POLITICA_CONTRATO', descriptionField: 'DESC_POLITICA_CONTRATO', commandName: 'ConsultarPoliticasContrato', providerField: 'POLITICAS_CONTRATO.POLITICA_CONTRATO' }),
    productionCenterType: new Entity({ fieldNameId: 'SIGLA_TIPO_CENTRO_PRODUÇÃO', descriptionField: 'DESC_TIPO_CENTRO_PRODUCAO', commandName: 'ConsultarTipoCentroProducao', providerField: 'TIPOS_CENTRO_PRODUCAO.TIPO_CENTRO_PRODUCAO' }),
    getStockMovementReason: new Entity({ fieldNameId: 'COD_MOTIVO_MOV_ESTOQUE', descriptionField: 'DESC_MOTIVO_MOV_ESTOQUE', commandName: 'ConsultarMotivosMovEstoque', providerField: 'MOTIVOS_MOV_ESTOQUE.MOTIVO_MOV_ESTOQUE', params: { ENTIDADE: '_entity', TRAZER_NOMES_CENTROS_ESTOQUES_ORIGEM_DESTINO: 0 } }),
    getEquipmentFamilies: new Entity({ fieldNameId: 'COD_FAMILIA', descriptionField: 'DESC_FAMILIA', commandName: 'ConsultarFamiliasEquipamentos', providerField: 'FAMILIAS.FAMILIA', params: { ENTIDADE: '_entity' } }),
    worksitesNoSave: new Entity({ fieldNameId: 'ID_PARQUE_SERVICO', descriptionField: 'NOME_PARQUE_SERVICO', commandName: 'ConsultarParquesServico', providerField: 'PARQUES_SERVICO.PARQUE_SERVICO', searchField: 'PALAVRA', params: { ATIVO: 1 } }),
    assetPattern: new Entity({ fieldNameId: 'ID_PADRAO_PS', descriptionField: 'DESC_PADRAO_PS', commandName: 'ConsultarPadroesPS', providerField: 'PADROES.PADRAO' }),
    linkedProject: new Entity({ fieldNameId: 'ID_OBRA', descriptionField: 'DESC_OBRA', commandName: 'ConsultarObrasVinculadas', providerField: 'OBRAS_VINCULADAS.OBRA_VINCULADA' }),
    projectContract: new Entity({ fieldNameId: 'ID_CONTRATO_OBRA', descriptionField: 'DESC_CONTRATO_OBRA', commandName: 'ContratosOrcamentosObras', providerField: 'CONTRATOS_OBRA.CONTRATO_OBRA' }),
    movementStockEmployeeReason: new Entity({ fieldNameId: 'COD_MOTIVO_MOV_ESTOQUE', descriptionField: 'DESC_MOTIVO_MOV_ESTOQUE', commandName: 'ConsultarMotivosMovEstoqueColaborador', providerField: 'MOTIVOS_MOV_ESTOQUE.MOTIVO_MOV_ESTOQUE' }),
    fuelTypesByEquipment: new Entity({ fieldNameId: 'SIGLA_TIPO_COMBUSTIVEL', descriptionField: 'DESC_TIPO_COMBUSTIVEL', commandName: 'ConsultarTipoCombustivelPorEquipamento', providerField: 'TIPOS_COMBUSTIVEL_EQUIPAMENTO.TIPO_COMBUSTIVEL_EQUIPAMENTO', params: { ID_EQUIPAMENTO: '_equipmentId' } }),
    script: new Entity({ fieldNameId: 'ID_SCRIPT', descriptionField: 'NAME', commandName: 'ConsultarScripts', providerField: 'SCRIPTS.SCRIPT' }),
    operationNature: new Entity({ fieldNameId: 'ID_NATUREZA_OPERACAO', descriptionField: 'DESC_NATUREZA_OPERACAO', commandName: 'ConsultarNaturezaOperacao', providerField: 'NATUREZA_OPERACOES.NATUREZA_OPERACAO' }),
    taxationRegimen: new Entity({ fieldNameId: 'ID_REGIME_TRIBUTACAO', descriptionField: 'DESC_REGIME_TRIBUTACAO', commandName: 'ConsultarRegimeTributacao', providerField: 'REGIME_TRIBUTACOES.REGIME_TRIBUTACAO' }),
    simpleNationalRevenue: new Entity({ fieldNameId: 'ID_FAIXA_FATURAMENTO', descriptionField: 'DESC_FAIXA_FATURAMENTO', commandName: 'ConsultarFaixaFaturaSimplesNacional', providerField: 'FATURAMENTOS.FATURAMENTO' }),
    simpleNationalAttachment: new Entity({ fieldNameId: 'NUMERO_ANEXO', descriptionField: 'DESCRICAO_ANEXO', commandName: 'ConsultarAnexoSimplesNacional', providerField: 'ANEXOS.ANEXO' }),
    budgetCostMethod: new Entity({ fieldNameId: 'SIGLA_METODO_CUSTO', descriptionField: 'DESC_METODO_CUSTO', commandName: 'ConsultarMetodosCustoOrcamento', providerField: 'METODOS.METODO' }),
    equipmentProblems: new Entity({ fieldNameId: 'ID_PROBLEMA_EQUIPAMENTO', descriptionField: 'DESCRICAO_PROBLEMA_EQUIPAMENTO', commandName: 'ConsultarProblemasEquipamento', providerField: 'PROBLEMASS_EQUIPAMENTOS.PROBLEMAS_EQUIPAMENTO' }),
    projectBudget: new Entity({ fieldNameId: 'ID_ORCAMENTO', descriptionField: 'DESC_ORCAMENTO', commandName: 'ConsultarOrcamentoObra', providerField: 'ORCAMENTOS_OBRA.ORCAMENTO_OBRA' }),
    templatesPhoto: new Entity({ fieldNameId: 'ID_TEMPLATE_FOTO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTemplatesFoto', providerField: 'TEMPLATES_FOTO.TEMPLATE_FOTO' }),
    systemPackages: new Entity({ fieldNameId: 'ID_PACOTE_SISTEMA', descriptionField: 'DESC_PACOTE_SISTEMA', commandName: 'ConsultarPacotesSistema', providerField: 'PACOTES_SISTEMA.PACOTE_SISTEMA' }),
    entities: new Entity({ fieldNameId: 'SIGLA_ENTIDADE', descriptionField: 'DESC_ENTIDADE', commandName: 'ConsultarEntidades', providerField: 'ENTIDADES.ENTIDADE' }),
    familiesStructuresRules: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'DESC_FAMILIA', commandName: 'ConsultarFamiliasRegrasEstruturas', providerField: 'FAMILIAS.FAMILIA' }),
    attributesSchemeFamily: new Entity({ fieldNameId: 'ID_ATRIBUTO', descriptionField: 'NOME', commandName: 'ConsultarAtributosEsquemaAtributoFamilia', providerField: 'ATRIBUTOS.ATRIBUTO' }),
    subdivisionStatus: new Entity({ fieldNameId: 'ID_STATUS_LOTE', descriptionField: 'DESC_STATUS_LOTE', commandName: 'ConsultarStatusLotes', providerField: 'STATUS_LOTES.STATUS_LOTE' }),
    subdivisionApprovalEntity: new Entity({ fieldNameId: 'ID_ENTIDADE_APROVACAO', descriptionField: 'DESC_ENTIDADE_APROVACAO', commandName: 'ConsultarEntidadesAprovacaoLote', providerField: 'ENTIDADES_APROVACAO.ENTIDADE_APROVACAO' }),
    processTypes: new Entity({ fieldNameId: 'KEY_PROCESSO', descriptionField: 'DESC_TIPO_PROCESSO', commandName: 'ConsultarTipoProcessoCamunda', providerField: 'TIPOS_PROCESSO.TIPO_PROCESSO' }),
    processTypesInboundEndpoint: new Entity({ fieldNameId: 'BPMN_PROCESS_ID', descriptionField: 'DESC_TIPO_PROCESSO', commandName: 'ConsultarInboundEndpoint', providerField: 'TIPOS_PROCESSO.TIPO_PROCESSO' }),
    currentActivity: new Entity({ fieldNameId: 'TASK_DEFINITION_KEY', descriptionField: 'DESC_STATUS_PROCESSO', commandName: 'ConsultarAtividadesProcesso', providerField: 'ATIVIDADES.ATIVIDADE' }),
    deadlineWindowType: new Entity({ fieldNameId: 'ID_TIPO_JANELA_PRAZO', descriptionField: 'DESCRICAO', commandName: 'ConsultarTipoJanelaPrazo', providerField: 'TIPOS_JANELA_PRAZO.TIPO_JANELA_PRAZO', editDialog: { page: { name: 'DeadlineWindowType' }, pageConfig: { isDialog: true, dialog: { fullscreen: false } }, params: { ID_TIPO_JANELA_PRAZO: '_deadlineWindowTypeId' } } }),
    ticketDpoOriginType: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_SOLICITACAO_DPO', descriptionField: 'DESC_TIPO_ORIGEM', commandName: 'ConsultarTipoOrigemSolicitacaoDpo', providerField: 'TIPOS_ORIGENS_SOLICITACAO_DPO.TIPO_ORIGEM_SOLICITACAO_DPO' }),
    ticketDpoType: new Entity({ fieldNameId: 'ID_TIPO_SOLICITACAO_DPO', descriptionField: 'DESC_TIPO_SOLICITACAO_DPO', commandName: 'ConsultarSolicitacaoDpoPortal', providerField: 'TIPOS_SOLICITACAO.TIPO_SOLICITACAO', params: { ATIVO: 1 } }),
    ticketDpoStatus: new Entity({ fieldNameId: 'ID_STATUS_SOLICITACAO_DPO', descriptionField: 'DESC_STATUS_SOLICITACAO', commandName: 'ConsultarStatusSolicitacaoDpo', providerField: 'STATUS_SOLICITACAO_DPO.STATUS' }),
    service: new Entity({ fieldNameId: 'ID_ITEM', descriptionField: 'DESC_ITEM', commandName: 'ConsultarMateriaisServicos', providerField: 'ITENS.ITEM', params: { SOMENTE_SERVICOS: 1 } }),
    products: new Entity({ fieldNameId: 'ID_PRODUTO', descriptionField: 'DESC_PRODUTO', commandName: 'ConsultarProdutos', providerField: 'PRODUTOS.PRODUTO' }),
    versionProduct: new Entity({ fieldNameId: 'ID_VERSAO_PRODUTO', descriptionField: 'COD_VERSAO_PRODUTO', commandName: 'ConsultarVersaoProdutos', providerField: 'VERSAO_PRODUTOS.VERSAO_PRODUTO' }),
    instance: new Entity({ fieldNameId: 'ID_INSTANCIA', descriptionField: 'COD_INSTANCIA', commandName: 'ConsultarInstanciaServidor', providerField: 'INSTANCIAS_SERVIDORS.INSTANCIA_SERVIDOR' }),
    server: new Entity({ fieldNameId: 'ID_SERVIDOR', descriptionField: 'NOME_SERVIDOR', commandName: 'ConsultarServidor', providerField: 'SERVIDORES.SERVIDOR' }),
    attributesByCategory: new Entity({ fieldNameId: 'ID_ESQUEMA_ATRIBUTOS', descriptionField: 'DESC_ESQUEMA_ATRIBUTOS', commandName: 'ConsultarEsquemasAtributosPorCategoria', providerField: 'ESQUEMAS_ATRIBUTOS.ESQUEMA_ATRIBUTOS' }),
    getUnityProduction: new Entity({ fieldNameId: 'DESC_UN_PRODUCAO', descriptionField: 'DESC_UN_PRODUCAO', commandName: 'ConsultarUnidadesProducao', providerField: 'UNIDADES_PRODUCAO.UNIDADE_PRODUCAO' }),
    getExecutionDefinitionMethod: new Entity({ fieldNameId: 'SIGLA_METODO_DEFINICAO_EXECUCAO', descriptionField: 'DESC_METODO_DEFINICAO_EXECUCAO', commandName: 'ConsultarMetodoDefinicaoExecucao', providerField: 'METODOS_DEFINICAO_EXECUCAO.METODO_DEFINICAO_EXECUCAO' }),
    structureItemsToDeviceAssociation: new Entity({ fieldNameId: 'ID_ITEM_ESTRUTURA_PS', descriptionField: 'DESC_ITEM_FULL', commandName: 'ConsultarItensEstruturaParaVinculoDispositivo', providerField: 'ITENS.ITEM', params: { ID_PONTO_SERVICO: '_assetId', APENAS_DISPONIVEIS: '_onlyAvaliables' } }),
    measurementType: new Entity({ fieldNameId: 'ID_TIPO_MEDICAO', descriptionField: 'DESC_TIPO_MEDICAO', commandName: 'ConsultarTiposMedicao', providerField: 'TIPOS_MEDICAO.TIPO_MEDICAO' }),
    qrCodeModel: new Entity({ fieldNameId: 'ID_MODELO_QRCODE', descriptionField: 'DESC_MODELO', commandName: 'ConsultarModeloQRCode', providerField: 'MODELOS_QRCODE.MODELO_QRCODE', searchField: 'DESC_MODELO' }),
    controlType: new Entity({ fieldNameId: 'ID_TIPO_CONTROLE', descriptionField: 'DESC_TIPO_CONTROLE', commandName: 'ConsultarTipoControle', providerField: 'TIPOS_CONTROLE.TIPO_CONTROLE' }),
    allCities: new Entity({ fieldNameId: 'LOC_NU', descriptionField: 'LOC_NO_UF', commandName: 'ConsultarMunicipios', providerField: 'MUNICIPIOS.MUNICIPIO' }),
    countyByState: new Entity({ fieldNameId: 'ID_MUNICIPIO', descriptionField: 'NOME_MUNICIPIO', commandName: 'BuscaMunicipiosPorUF', providerField: 'MUNICIPIOS.MUNICIPIO', params: {} }),
    modelTypeTag: new Entity({ fieldNameId: 'ID_TIPO_MODELO', descriptionField: 'DESC_TIPO_MODELO', commandName: 'ConsultarTiposModelo', providerField: 'TIPOS_MODELO.TIPO_MODELO' }),
    plan: new Entity({ fieldNameId: 'ID_PLANO', descriptionField: 'NOME_PLANO', commandName: 'ConsultarPlanosExati', providerField: 'PLANOS.PLANO' }),
    menuTemplate: new Entity({ fieldNameId: 'ID_TEMPLATE_MENU', descriptionField: 'NOME_TEMPLATE_MENU', commandName: 'ConsultarTemplatesMenuInterfaceUsuario', providerField: 'TEMPLATES_MENU.TEMPLATE_MENU' }),
    roadType: new Entity({ fieldNameId: 'ID_TIPO_VIA', descriptionField: 'DESC_TIPO_VIA', commandName: 'ConsultarTipoVia', providerField: 'TIPOS_VIAS.TIPO_VIA', params: { APENAS_VIAS: 1 } }),
    getStatusMaintenanceLog: new Entity({ fieldNameId: 'ID_STATUS_REGISTRO_MANUTENCAO', descriptionField: 'DESC_STATUS_REGISTRO_MANUTENCAO', commandName: 'ConsultarStatusRegistroManutencao', providerField: 'STATUS_REGISTRO_MANUTENCAO.STATUS' }),
    getEquipmentMaintenanceSchedule: new Entity({ fieldNameId: 'ID_AGEND_MANUT_EQUIP', descriptionField: 'DESCRICAO', commandName: 'ConsultarAgendManutEquip', providerField: 'AGENDS_MANUTS_EQUIPS.AGEND_MANUT_EQUIP' }),
    versionsAssetGrouping: new Entity({ fieldNameId: 'ID_VERSAO_AGRUPAMENTO_PONTO_SERVICO', descriptionField: 'VERSAO', commandName: 'ConsultarVersoesAgrupamentoPontoServico', providerField: 'VERSOES.VERSOES' }),
    assetGroupingStatus: new Entity({ fieldNameId: 'ID_STATUS_AGRUPAMENTO_PS', descriptionField: 'DESC_STATUS_AGRUPAMENTO_PONTO_SERVICO', commandName: 'ConsultarStatusAgrupamentoPontoServico', providerField: 'STATUS_AGRUPAMENTO_PONTO_SERVICO.STATUS_AGRUPAMENTO_PONTO_SERVICO' }),
    assetTypeRelatedServices: new Entity({ fieldNameId: 'ID_SERVICO', descriptionField: 'DESC_SERVICO', commandName: 'ConsultarServicoPorPalavraTpPontoServico', providerField: 'SERVICOS.SERVICO', params: { BUSCAR_LIGADOS: 1 } }),
    ncallServiceQueue: new Entity({ fieldNameId: 'ID_FILA_NCALL', descriptionField: 'NOME_FILA', commandName: 'ConsultarFilasNcall', providerField: 'FILAS.FILA' }),
    ncallSubtab: new Entity({ fieldNameId: 'ID_SUBTABULACAO_NCALL', descriptionField: 'NOME_SUBTABULACAO', commandName: 'ConsultarSubtabulacoesNcall', providerField: 'SUBTABULACOES.SUBTABULACAO' }),
    reportType: new Entity({ fieldNameId: 'ID_TIPO_LAUDO', descriptionField: 'DESC_TIPO_LAUDO', commandName: 'ConsultarTipoLaudo', providerField: 'TIPOS_LAUDO.TIPO_LAUDO' }),
    servicesSelect: new Entity({ fieldNameId: 'ID_SERVICO', descriptionField: 'DESC_SERVICO', commandName: 'ConsultarServicoPorPalavra', providerField: 'SERVICOS.SERVICO', searchField: 'PALAVRA', minToSearch: 3 }),
    userCompany: new Entity({ fieldNameId: 'ID_PESSOA', descriptionField: 'NOME', commandName: 'ConsultarEmpresasUsuariasPorPalavra', providerField: 'PESSOAS.PESSOA', searchField: 'PALAVRA' }),
    issueRescheduleReason: new Entity({ fieldNameId: 'ID_MOTIVO_REPROGRAMACAO_OCORRENCIA', descriptionField: 'NOME_MOTIVO_REPROGRAMACAO_OCORRENCIA', commandName: 'ConsultarMotivoReprogramacaoOcorrencia', providerField: 'MOTIVOS_REPROGRAMACAO_OCORRENCIA.MOTIVO_REPROGRAMACAO_OCORRENCIA' }),
    pageConstructors: new Entity({ fieldNameId: 'ID_MODULO_VISUAL', descriptionField: 'DESC_MODULO_VISUAL', commandName: 'ConsultarModulosVisuais', providerField: 'MODULOS_VISUAIS.MODULO_VISUAL', params: { VERSAO: 'GVUE' } }),
    complainer: new Entity({ fieldNameId: 'ID_RECLAMANTE', descriptionField: 'NOME_COMPLETO', commandName: 'ConsultarReclamante', providerField: 'RECLAMANTE' }),
    ombudsmanRequestOriginType: new Entity({ fieldNameId: 'ID_TIPO_ORIGEM_OUVIDORIA', descriptionField: 'DESC_TIPO_ORIGEM_OUVIDORIA', commandName: 'ConsultarTiposOrigemRequisicaoOuvidoria', providerField: 'TIPOS_ORIGEM_OUVIDORIA.TIPO_ORIGEM_OUVIDORIA' }),
    ombudsmanRequestPriority: new Entity({ fieldNameId: 'ID_PRIORIDADE_OUVIDORIA', descriptionField: 'DESC_PRIORIDADE_OUVIDORIA', commandName: 'ConsultarPrioridadesRequisicaoOuvidoria', providerField: 'PRIORIDADES_OUVIDORIA.PRIORIDADE_OUVIDORIA' }),
    ombudsmanRequestReason: new Entity({ fieldNameId: 'ID_MOTIVO_OUVIDORIA', descriptionField: 'DESC_MOTIVO_OUVIDORIA', commandName: 'ConsultarMotivosRequisicaoOuvidoria', providerField: 'MOTIVOS_OUVIDORIA.MOTIVO_OUVIDORIA', params: { APENAS_ATIVOS: 1 } }),
    ombudsmanRequestStatus: new Entity({ fieldNameId: 'ID_STATUS_OUVIDORIA', descriptionField: 'DESC_STATUS_OUVIDORIA', commandName: 'ConsultarStatusRequisicaoOuvidoria', providerField: 'STATUS_OUVIDORIA.STATUS_OUVIDORIA' }),
    ombudsmanRequestResolutionType: new Entity({ fieldNameId: 'ID_TIPO_RESOLUCAO_OUVIDORIA', descriptionField: 'DESC_TIPO_RESOLUCAO_OUVIDORIA', commandName: 'ConsultarTiposResolucaoOuvidoria', providerField: 'TIPOS_RESOLUCAO_OUVIDORIA.TIPO_RESOLUCAO_OUVIDORIA', params: { APENAS_ATIVOS: 1 } }),
    financialAccounts: new Entity({ fieldNameId: 'ID_CENTRO_FINANC', descriptionField: 'COD_CENTRO_FINANC', commandName: 'ConsultarContasFinanceiras', providerField: 'CONTAS.CONTA' }),
    financialCategory: new Entity({ fieldNameId: 'ID_CATEGORIA', descriptionField: 'DESC_CATEGORIA', commandName: 'ConsultarCategoriasFinanceiras', providerField: 'CATEGORIA' }),
    financialCenters: new Entity({ fieldNameId: 'ID_CENTRO_FINANC', descriptionField: 'DESC_CENTRO_FINANC', commandName: 'ConsultarCentrosFinanc', providerField: 'CENTRO_FINANC' }),
    holidayCalendars: new Entity({ fieldNameId: 'ID_CALENDARIO_FERIADOS', descriptionField: 'DESC_CALENDARIO_FERIADOS', commandName: 'ConsultarCalendariosFeriados', providerField: 'CALENDARIOS.CALENDARIO' }),
    nCallStatus: new Entity({ fieldNameId: 'STATUS', descriptionField: 'STATUS', commandName: 'ConsultarStatusNCall', providerField: 'STATUS_NCALL.STATUS' }),
    ncallTabs: new Entity({ fieldNameId: 'ID_TABULACAO_NCALL_EXTERNO', descriptionField: 'NOME_TABULACAO', commandName: 'ConsultarTabulacoesNcall', providerField: 'TABULACOES.TABULACAO' }),
    tasksProcessDefinition: new Entity({ fieldNameId: 'ID_TAREFA', descriptionField: 'NOME_TAREFA', commandName: 'ConsultarTarefasDefinicaoProcesso', providerField: 'TAREFAS.TAREFA', params: { ID_DEFINICAO_PROCESSO: '_assetId' } }),
    areaTypes: new Entity({ fieldNameId: 'ID_TIPO_AREA', descriptionField: 'DESC_TIPO_AREA', commandName: 'ConsultarTiposArea', providerField: 'TIPOS_AREA.TIPO_AREA' }),
    groupStatusEntities: new Entity({ fieldNameId: 'ID_GRUPO_STATUS_ENTIDADE', descriptionField: 'DESC_ENTIDADE', commandName: 'ConsultarGrupoStatusEntidades', providerField: 'GRUPO_STATUS_ENTIDADES.GRUPO_STATUS_ENTIDADE' }),
    families: new Entity({ fieldNameId: 'ID_FAMILIA', descriptionField: 'COD_FAMILIA', commandName: 'ConsultarFamilias', providerField: 'FAMILIAS.FAMILIA' }),
    trackerIntegration: new Entity({ fieldNameId: 'ID_INTEGRACAO_RASTREAMENTO', descriptionField: 'DESCRICAO', commandName: 'ConsultarIntegracoesRastreadores', providerField: 'INTEGRACOES.INTEGRACAOO' }),
};

export default defaultProvider;
