import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '@/pages/remoteManagement/services/utils';
export class GetBillingEstimation extends CallCommand {
    constructor() {
        super({
            name: 'post_billing-report-estimate_0_1',
            type: 'NONE',
            fields: {
                initialDate: new Field({
                    name: 'PATH_PARAMS',
                }),
                finalDate: new Field({
                    name: 'PATH_PARAMS',
                }),
                deviceGroupsIds: new Field({
                    name: 'BODY',
                }),
                deviceIds: new Field({
                    name: 'BODY',
                }),
                scheduleId: new Field({
                    name: 'BODY',
                }),
            },
            customRequestParser: function (params) {
                const filter = {
                    deviceGroupsIds: params.deviceGroupsIds,
                    deviceIds: params.deviceIds,
                    scheduleId: params.scheduleId,
                };
                return {
                    PATH_PARAMS: JSON.stringify({
                        PARAM_0: params.initialDate,
                        PARAM_1: params.finalDate,
                    }),
                    BODY: JSON.stringify(filter),
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(params, config, debug) {
        return super.callCommand(params, { silent: true, ...config }, debug);
    }
}
